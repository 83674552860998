/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const CheckIcon: FC<IconProps> = ({ color = 'white', width = 15, height = 12 }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 12" fill="none">
    <path d="M13.5 2L5.25 10.25L1.5 6.5" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CheckIcon;
