import React from 'react';
import PropTypes from 'prop-types';
import { CODE_ENDING } from 'shared-ui/src/constants/commons';

function CodePromoButton({ onClick }) {
  return (
    // eslint-disable-next-line
    <a
      className="btn btn--coupon btn--coupon-cover mb-3"
      rel="nofollow"
      onClick={onClick}
    >
      <b className="btn--coupon-text">GET MY CODE</b>
      <span className="btn--coupon-layer-container">
        <b className="btn--coupon-code">{CODE_ENDING}</b>
        <span className="btn--coupon-layer" />
        <span className="btn--coupon-image" />
      </span>
    </a>
  );
}

CodePromoButton.defaultProps = {
  onClick: PropTypes.func.isRequired,
};

export default CodePromoButton;
