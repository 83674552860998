import isArray from 'lodash/isArray';
import map from 'lodash/map';

type FilterableObject = {
  [k: string]: any;
};

/**
 * Filters the properties of an object.
 * @param obj any object.
 * @param callback filter callback.
 */
export function filterObject<T extends FilterableObject>(
  obj: T,
  callback: (key: string, value: any) => boolean,
): FilterableObject {
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([key, value]) => callback(key, value));

  return Object.fromEntries(filtered);
}

export function formatObjectToArray(arr: { [k: string]: any }, parentKey?: string): any {
  return map(arr, (value, key) => {
    const itemKey = isArray(value) || !parentKey ? `${key}` : parentKey;

    return isArray(value) ? formatObjectToArray(value, itemKey) : `${itemKey}-${value}`;
  });
}
