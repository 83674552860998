import React, { FC } from 'react';
import { PromotionType } from '../../../types/promotion';

export const PROMO_TYPE_CHIP_TEST_ID = 'promo-type-chip';
export const PROMO_TYPE_CHIP_ONLINE_TEXT = 'discount';
export const PROMO_TYPE_CHIP_REBATE_TEXT = 'cash back';

export type PromoTypeChipProps = {
  promotionType: PromotionType;
};

const PromoTypeChip: FC<PromoTypeChipProps> = ({ promotionType }) => {
  return (
    <div data-testid={PROMO_TYPE_CHIP_TEST_ID} className={`promo-type-chip ${promotionType.toLowerCase()}`}>
      {promotionType === PromotionType.ONLINE ? PROMO_TYPE_CHIP_ONLINE_TEXT : PROMO_TYPE_CHIP_REBATE_TEXT}
    </div>
  );
};

export default PromoTypeChip;
