import React, { FC, memo, useState } from 'react';
import cls from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { CompareItem as ICompareItem } from '../../../../types/mattress-comparison';
import Button from '../../../atoms/Button';
import styles from './CompareItem.module.scss';
import useCompareWidgetState from '../store';
import { MAX_COMPARE_ITEMS } from '../../../../constants/compare';
import CircleCloseIcon from '../../../atoms/Icons/CircleCloseIcon';
import CheckIcon from '../../../atoms/Icons/Check';
import PlusIcon from '../../../atoms/Icons/Plus';

export interface CompareItemProps {
  compareItem: ICompareItem;
  hasProduct?: boolean;
  canAddProduct?: boolean;
}

const DEFAULT_LOGO_SRC = '/static/major/img/no-image-gray.png';

export const BASE_CTA_DATA = {
  level: CTAClickLevel.PRIMARY,
  pageRegion: PageRegion.COMPARE,
};

const CompareItem: React.FunctionComponent<CompareItemProps> = ({ compareItem }: CompareItemProps) => {
  const { slug, name, img, logo } = compareItem;
  const [imgHasError, setImgError] = useState<boolean>(false);

  const { removeItem } = useCompareWidgetState();

  const noImage = imgHasError || isEmpty(img);

  return (
    <li className={cls(styles.baseCompareItem, styles.compareItem)}>
      <header>
        <Button
          className={styles.compareItemRemoveBtn}
          size="sm"
          ariaLabel="Remove Compare Item"
          ctaData={{
            ...BASE_CTA_DATA,
            category: CTAClickCategory.REMOVE_COMPARE_ITEM,
          }}
          onClick={(e): void => {
            e.stopPropagation(); // avoid triggering compare select action `handleClick`
            removeItem(compareItem.slug);
          }}
        >
          Remove
          <CircleCloseIcon />
        </Button>
      </header>
      <img
        className={cls(styles.compareItemImg, { [styles.noImage]: noImage })}
        src={noImage ? DEFAULT_LOGO_SRC : img}
        alt={slug}
        onError={(): void => {
          setImgError(true);
        }}
      />

      <div className={styles.compareItemBody}>
        <img className={styles.compareItemLogo} src={logo} alt={name} />
        <b title={name} className={styles.compareItemName}>
          {name}
        </b>
      </div>
    </li>
  );
};

interface EmptyCompareItemProps {
  text?: string;
  onClick?: () => void;
}

export const EmptyCompareItem: FC<EmptyCompareItemProps> = memo(({ text, onClick }) => {
  const description = text || 'Add another mattress to compare';

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    <li className={cls(styles.emptyCompareItem, styles.baseCompareItem)} onClick={onClick} role="button">
      <p>{description}</p>
    </li>
  );
});

export const ExtraProductCompareItem: FC<CompareItemProps> = ({ compareItem }) => {
  const { slug, name, img, logo } = compareItem;
  const [imgHasError, setImgError] = useState<boolean>(false);

  const { selectItem, removeItem, compareItems } = useCompareWidgetState();

  const canAddProduct = compareItems.length < MAX_COMPARE_ITEMS;
  const selected = !!compareItems.find((item) => item.slug === compareItem.slug);

  const handleClick = (): void => {
    if (selected) removeItem(slug);
    else selectItem(compareItem);
  };

  const noImage = imgHasError || isEmpty(img);

  return (
    <div
      className={cls(styles.baseCompareItem, styles.compareItem, styles.extraProductCompareItem, {
        [styles.selected]: selected,
      })}
    >
      <div className={cls(styles.addToCompareButtonContainer)}>
        {selected ? <span className={styles.selectedText}>Added</span> : null}

        <Button
          className={cls(styles.addToCompareButton, { [styles.addToCompareButtonSelected]: selected })}
          ariaLabel="Add to compare"
          onClick={handleClick}
          disabled={!canAddProduct && !selected}
          ctaData={{
            category: CTAClickCategory.COMPARE,
            level: CTAClickLevel.SECONDARY,
            pageRegion: PageRegion.COMPARE_WIDGET,
          }}
        >
          {selected ? (
            <CheckIcon />
          ) : (
            <PlusIcon className={styles.addToCompareButtonAddIcon} color="#fff" width="24" height="24" />
          )}
        </Button>
      </div>

      <img
        className={cls(styles.compareItemImg, { [styles.noImage]: noImage })}
        src={noImage || isEmpty(img) ? DEFAULT_LOGO_SRC : img}
        alt={slug}
        onError={(): void => {
          setImgError(true);
        }}
      />

      <div className={styles.compareItemBody}>
        <img className={styles.compareItemLogo} src={logo} alt={name} />
        <b title={name} className={styles.compareItemName}>
          {name}
        </b>
      </div>
    </div>
  );
};

export default memo(CompareItem);
