import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import InfoIcon from '../../atoms/Icons/Info';
import Popover from '../Popover';
import RatingBar from '../../atoms/Rating/Bar';

type RatingCategoryProps = {
  id?: string;
  label: string;
  content?: string;
  rating: number;
  isMobile: boolean;
  ratingValueLabel?: JSX.Element;
};

const RatingCategory: React.FunctionComponent<RatingCategoryProps> = ({
  id = '',
  label,
  content,
  rating, // Rating value should be based on 100 as the max.
  isMobile,
  ratingValueLabel,
}: RatingCategoryProps) => {
  return (
    <div className="mattress-rating-category" id={`${id}-category`}>
      <div className="mattress-rating-category-info">
        <div className="mattress-rating-category-label-container">
          <span className="mattress-rating-category-label">{label}</span>
          {!isEmpty(content) && (
            <Popover
              className="mattress-rating-popover"
              id={id}
              title={label}
              content={content}
              placement={isMobile ? 'top-start' : 'right'}
            >
              <div className="mattress-rating-popover__icon-container">
                <InfoIcon color="#ABABAB" />
              </div>
            </Popover>
          )}
        </div>

        <span className="mattress-rating-category-info__rating-value">{ratingValueLabel || rating || 'N/A'}</span>
      </div>

      <RatingBar rating={rating} />
    </div>
  );
};

export default memo(RatingCategory);
