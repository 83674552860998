/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const ProductDiscountBoxFoldIconSmall: FC<IconProps> = ({ className = 'fold-icon-small' }) => (
  <svg className={className} width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.407829 0.110979L3.08278 11.4675C3.46864 13.1057 4.83866 14.3271 6.51021 14.5232L16.237 15.6642C19.2529 15.7398 18.8095 15.6819 18.8324 14.9688L0.407829 0.110979Z"
      fill="#9BAFD5"
    />
  </svg>
);

export default ProductDiscountBoxFoldIconSmall;
