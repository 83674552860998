import DataLayerService from 'datalayer-service/src/datalayer';
import { CTAClickCategory, CTAClickLevel } from 'datalayer-service/src/types/enums';

const dataLayerService = new DataLayerService(window);

export function getProductAndStoreDataFromHTMLAttributes(element) {
  if (!element) {
    return { product: {}, store: {} };
  }

  let product = '';
  if (
    element.getAttribute('data-model') ||
    element.getAttribute('data-collection') ||
    element.getAttribute('data-line') ||
    element.getAttribute('data-manufacturer')
  ) {
    product = {
      model: element.getAttribute('data-model') || '',
      collection: element.getAttribute('data-collection') || '',
      line: element.getAttribute('data-line') || '',
      manufacturer: element.getAttribute('data-manufacturer') || '',
      status: element.getAttribute('data-status') || '',
      listed: element.getAttribute('data-listed') || '',
    };
  }

  let store = '';
  if (
    element.getAttribute('data-is-ldp') ||
    element.getAttribute('data-retailer-name') ||
    element.getAttribute('data-location')
  ) {
    store = {
      isLDP: element.getAttribute('data-is-ldp') || element.getAttribute('data-ldp') || '',
      retailerName: element.getAttribute('data-retailer-name') || element.getAttribute('data-retailer-slug') || '',
      retailerLocation: element.getAttribute('data-store-slug') || element.getAttribute('data-store-name') || '',
      retailerStatus: element.getAttribute('data-retailer-status') || '',
    };
  }
  return { product, store };
}

export function trackQuizCTAClickFrom(element) {
  const subcategory = element.getAttribute('data-subcategory') || '';
  const { product, store } = getProductAndStoreDataFromHTMLAttributes(element);

  const url = element.href;
  const pageRegion = element.getAttribute('data-page-region');

  dataLayerService.CTAClickEvent({
    category: CTAClickCategory.TAKE_THE_QUIZ,
    subcategory,
    level: CTAClickLevel.PRIMARY,
    pageRegion,
    product,
    store,
    url,
  });

  return true;
}

export function trackTakeQuizCTA() {
  document.querySelectorAll('a.match-score-box-btn,a.quiz_cta').forEach((element) => {
    element.addEventListener('click', () => {
      trackQuizCTAClickFrom(element);
    });
  });
}

export function trackShopStoreCTA() {
  document.querySelectorAll('a.store-website').forEach((element) => {
    element.addEventListener('click', () => {
      const url = element.href;

      const { product, store } = getProductAndStoreDataFromHTMLAttributes(element);

      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.SHOP_STORES,
        level: CTAClickLevel.PRIMARY,
        pageRegion: element.getAttribute('data-page-region'),
        url,
        store,
        product,
      });
    });
  });
}

export function trackGetDirectionsCTA() {
  document.querySelectorAll('.store-get-directions-btn').forEach((element) => {
    element.addEventListener('click', () => {
      const url = element.href;

      const { store } = getProductAndStoreDataFromHTMLAttributes(element);

      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.DIRECTIONS,
        level: CTAClickLevel.SECONDARY,
        pageRegion: element.getAttribute('data-page-region'),
        url,
        store,
      });
    });
  });
}

export function trackSaveExtraCTA() {
  document.querySelectorAll('.border-box-promotion').forEach((element) => {
    element.addEventListener('click', () => {
      const url = element.href;

      const { product, store } = getProductAndStoreDataFromHTMLAttributes(element);

      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.BORDER_BOX_PROMOTION,
        level: CTAClickLevel.SECONDARY,
        pageRegion: element.getAttribute('data-page-region'),
        url,
        store,
        product,
      });
    });
  });
}

export function trackShopBedCTA() {
  document.querySelectorAll('a.object-website').forEach((element) => {
    element.addEventListener('click', () => {
      const url = element.href;
      const pageRegion = element.getAttribute('data-page-region');

      const { product, store } = getProductAndStoreDataFromHTMLAttributes(element);

      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.SHOP_FOR_BEDS,
        level: CTAClickLevel.PRIMARY,
        pageRegion,
        url,
        product,
        store,
      });
    });
  });
}

export function trackFullReviewCTA() {
  document.querySelectorAll('a.read-full-review').forEach((element) => {
    element.addEventListener('click', () => {
      const url = element.href;
      const pageRegion = element.getAttribute('data-page-region');

      const { product, store } = getProductAndStoreDataFromHTMLAttributes(element);

      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.IN_DEPTH_REVIEWS,
        level: CTAClickLevel.PRIMARY,
        pageRegion,
        url,
        product,
        store,
      });
    });
  });
}

export function trackMiscActions() {
  const urlconf = {
    mattresses: 'manufacturer',
    'mattress-line': 'line',
    'mattress-model': 'model',
    'mattress-retailer': 'retailer',
    'mattress-store': 'store',
    'mattresses-new': 'manufacturer',
    'mattress-line-new': 'line',
    'mattress-model-new': 'model',
    'mattress-retailer-new': 'retailer',
    'mattress-store-new': 'store',
    'mattress-blog': 'blog',
    'mattress-store-locator': 'manufacturer-locator',
    'mattress-topics': 'topic',
    pro: 'person',
    user: 'person',
  };
  let trackslug = '';
  let chunks = [];
  const rawChunks = window.location.pathname.split('/');

  for (let i = 0; i < rawChunks.length; i++) {
    if (rawChunks[i]) chunks.push(rawChunks[i]);
  }

  if (chunks.length === 0) chunks = ['home'];
  let address;

  if (chunks.length >= 2) {
    trackslug = urlconf[chunks[0]];
    address = trackslug === 'person' ? chunks[2] : chunks[1];
  } else {
    trackslug = 'static';
    // eslint-disable-next-line
    address = chunks[0];
  }
  trackslug = `${trackslug}/${address}`;

  // external links in insight and ad boxes are to be tracked
  $('div.insight a').click((e) => {
    if (this.href.indexOf(window.location.host) !== 7) {
      const element = e.target;
      dataLayerService.CTAClickEvent({
        category: CTAClickCategory.OUTBOUND_LINK,
        url: `/outgoing/${trackslug}/insight/${this.href}`,
        pageRegion: element.getAttribute('data-page-region') || '',
      });
    }
  });

  // links to external websites from deals box
  $('a.deal-link').click((e) => {
    const element = e.target;

    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/promo/${this.getAttribute('data-outslug')}/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });
  // links to external websites from category spotlight box
  $('div.own-promo a').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/promo/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  // and other social links at all
  $('a.social-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/social/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  // links in about boxes
  $('a.about-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/about/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  $('a.top-ad-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/top/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  $('a.right-ad-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/right/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  $('a.right_lower-ad-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/right-lower/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });

  $('a.left-ad-link').click((e) => {
    const element = e.target;
    dataLayerService.CTAClickEvent({
      category: CTAClickCategory.OUTBOUND_LINK,
      url: `/outgoing/${trackslug}/left/${this.href}`,
      pageRegion: element.getAttribute('data-page-region') || '',
    });
  });
}
