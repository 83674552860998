import { useEffect, useState } from 'react';
import { captureException } from '@sentry/browser';
import fetchService from '../../utils/fetchService';
import { ProductDealResponse } from '../../types/deal';

const INITIAL_STATE = {
  brand: undefined,
  deals: [],
  product: undefined,
  hasRequestFinished: false,
};

const DEALS = 'deals';
const NEAREST_DEALS = 'nearest-deals';

interface ProductDeal extends ProductDealResponse {
  hasRequestFinished: boolean;
}

const useFetchProductDeal = (
  productClassname: string,
  productSlug: string,
  nearestDeals = false,
  onlyRebate = true,
): ProductDeal => {
  const [data, setData] = useState<ProductDeal>(INITIAL_STATE);

  useEffect(() => {
    fetchService
      .get<ProductDeal>(
        `/${productClassname}/${productSlug}/${nearestDeals ? NEAREST_DEALS : DEALS}/?only_rebate=${
          onlyRebate ? 'True' : 'False'
        }`,
      )
      .then((response) => {
        if (response && response.brand && response.deals) {
          setData({
            ...response,
            hasRequestFinished: true,
          });
        }
      })
      .catch((err) => {
        setData(INITIAL_STATE);

        const msg = 'Error fetching product promos';
        console.error(msg, err);
        captureException(new Error(`${msg} -> ${err}`));
      });
  }, []);

  return data;
};

export default useFetchProductDeal;
