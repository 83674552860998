import React, { FC } from 'react';
import IncognitoPopover from '../../IncognitoPopover';

export const REWARD_SHOPPING_LINK_TEXT_ID = 'reward-shopping-link-text';

type RewardShoppingLinkTextProps = {
  retailerName: string;
};

const RewardShoppingLinkText: FC<RewardShoppingLinkTextProps> = ({ retailerName }) => {
  return (
    <div data-testid={REWARD_SHOPPING_LINK_TEXT_ID} className="reward-shopping-link-text">
      Copy / paste this personal shopping link into a new{' '}
      <IncognitoPopover>
        <span className="incognito-popover-text">private (or “incognito”) browser window</span>
      </IncognitoPopover>{' '}
      – this will open the {retailerName} website in a way that enables GoodBed to earn the referral fee that we share
      with you.
    </div>
  );
};

export default RewardShoppingLinkText;
