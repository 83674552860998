(() => {

  function center_image() {
    let img_height;
    let wrapper_height;
    let img_width;
    let wrapper_width;
    let wrapper;
    let img;
    let wrappers = $('.valign-img');
    let i = wrappers.length;

    while (i--) {
      wrapper = $(wrappers[i]);
      img = wrapper.find('img');

      img_height = img.height();
      wrapper_height = wrapper.height();

      img_width = img.width();
      wrapper_width = wrapper.width();

      const overlap_h = (wrapper_height - img_height) / 2;
      const overlap_w = (wrapper_width - img_width) / 2;
      img.css('margin-top', overlap_h);
      img.css('margin-left', overlap_w);
    }
  }

  $(window).on('load resize', center_image);

})();
