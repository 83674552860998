import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const CircleCloseIcon: FC<IconProps> = ({ className, color = '#fff', width = '20', height = '20' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
    <path
      d="M14 13.9121L10 9.91211M10 9.91211L6 13.9121M10 9.91211L6 5.91211M10 9.91211L14 5.91211"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCloseIcon;
