import React, { FC } from 'react';
import Popover from '../Popover';
import IncognitoAccordion from '../../atoms/IncognitoAccordion';

export type IncognitoPopoverProps = {
  children: JSX.Element | string;
};

const IncognitoPopover: FC<IncognitoPopoverProps> = ({ children }) => {
  const incognitoPopoverContent = (): JSX.Element => {
    return (
      <div className="incognito-popover__content">
        <div className="incognito-popover__content-title">
          Here&rsquo;s how to open a private/incognito window in each type of device and browser.
        </div>

        <IncognitoAccordion />
      </div>
    );
  };

  return (
    <Popover
      id="incognito"
      className="incognito-popover"
      title="Open a private/incognito window"
      content={incognitoPopoverContent()}
    >
      {children}
    </Popover>
  );
};

export default IncognitoPopover;
