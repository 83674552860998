// jshint -W106
import { LDP_METRICS_STORE_CLASSNAME } from './constants';

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const Utils = (() => {
  const exports = {};

  /**
   * Helper function to create data-js selectors
   *
   * @param {string} value - The value of the data-js attribute.
   * @param {string} [attrName=''] - Additional attribute name to append to 'data-js'.
   * @example
   *   Utils.datajs('track'); // returns '[data-js="track"]'
   * @example
   *   // returns '[data-js-paginator-step="3"]'
   *   Utils.datajs('3', 'paginator-step');
   * @returns {string} - The data-js selector.
   */
  exports.datajs = (value, attrName) => {
    return '[data-js{0}="{1}"]'.format(attrName ? `-${attrName}` : '', value);
  };

  exports.isEmptyField = (field) => {
    return (field.attr('type') === 'radio' && !field.filter(':checked').val()) || !field.val();
  };

  return exports;
})();

// http://detectmobilebrowsers.com/
// Wed Mar 18 11:30:25 2015
// The current regex (August 2014) does NOT consider tablets like iPads and Google Nexus 7
// to be a mobile device.
export const isMobile = (() => {
  let check = false;

  ((a) => {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
})();

export function getCookie(cookieName) {
  let cookieVal = null;
  if (document.cookie) {
    // only if exists

    const arr = document.cookie.split(`${encodeURIComponent(cookieName)}=`);
    if (arr.length >= 2) {
      const arr2 = arr[1].split(';');
      cookieVal = decodeURIComponent(arr2[0]);
    }
  }
  return cookieVal;
}

export function setCookie(cookieName, value, expiry, path, samesite) {
  let cookie = `${cookieName}=${value};`;

  if (expiry) {
    const d = new Date();
    d.setTime(d.getTime() + expiry);
    cookie += ` expires=${d.toUTCString()};`;
  }

  if (path) {
    cookie += ` path=${path};`;
  }

  cookie += ` SameSite=${samesite || 'Lax'};`;

  document.cookie = cookie;
}

export function getParameterByName(name, url) {
  let newName = name;
  let newUrl = url;

  if (!url) {
    newUrl = window.location.href;
  }
  newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(newUrl);
  if (!results) {
    return undefined;
  }
  return !results[2] ? '' : decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function addOrRemoveClass(element, cls, show) {
  if (element) {
    if (show) element.classList.add(cls);
    else element.classList.remove(cls);
  }
}

export function ldpMetricsAction(locationPage, action, slug, objectClassname = LDP_METRICS_STORE_CLASSNAME) {
  $.ajax({
    url: '/api/v1/partners/ldp-metrics/action',
    method: 'POST',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    data: {
      location_page: locationPage,
      action_type: action,
      slug,
      request_path: `${window.location.pathname}${window.location.search}`,
      object_classname: objectClassname,
    },
  });
}

export const parseBoolean = (value) => value === 'true';

export const getBooleanAttributeValue = (attribute, fallbackValue = false) => {
  if (!attribute) return fallbackValue;

  const valueToCompare = fallbackValue ? 'true' : 'false';
  return attribute === valueToCompare ? fallbackValue : !fallbackValue;
};

export const secureGetAttribute = (element, attribute = '') => {
  if (!element || !element.hasAttribute(attribute)) return undefined;

  const attributeValue = element.getAttribute(attribute);

  if (attributeValue === 'None') return undefined;

  return attributeValue;
};

(() => {
  // Polyfill for IE<9
  if (!Array.indexOf) {
    // eslint-disable-next-line
    Array.prototype.indexOf = function (obj, start) {
      for (let i = start || 0; i < this.length; i++) {
        if (this[i] === obj) {
          return i;
        }
      }

      return -1;
    };
  }

  /**
   * Simple templating
   *
   * Use: '<a href="{0}">{1}</a>'.format("http://example.com", "Example");
   * See: http://stackoverflow.com/a/14062431/96213
   */
  // eslint-disable-next-line
  String.prototype.format = function () {
    // eslint-disable-next-line
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  };

  // eslint-disable-next-line
  String.prototype.pluralize = (count, plural) => {
    let newPlural = plural;

    if (plural === null) {
      newPlural = `${this}s`;
    }

    return count === 1 ? this : newPlural;
  };

  // eslint-disable-next-line
  Number.prototype.toPrettyNumber = function () {
    const parts = this.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  if (!String.prototype.trim) {
    // eslint-disable-next-line
    String.prototype.trim = function () {
      return this.replace(/^\s+|\s+$/g, '');
    };
  }
})();

/**
  Util function to insert HTML in an element.
  The difference with the native Element.innerHTML method
  is that this function runs the 'script' tags inside the HTML.
*/
export function setInnerHTML(elm, html) {
  // eslint-disable-next-line no-param-reassign
  elm.innerHTML = html;

  Array.from(elm.querySelectorAll('script')).forEach((oldScriptEl) => {
    const newScriptEl = document.createElement('script');

    Array.from(oldScriptEl.attributes).forEach((attr) => {
      newScriptEl.setAttribute(attr.name, attr.value);
    });

    const scriptText = document.createTextNode(oldScriptEl.innerHTML);
    newScriptEl.appendChild(scriptText);

    oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
  });
}
