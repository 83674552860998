import React, { FC, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Overlay as BSOverlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import Button from '../Button';
import { PopoverOverlayProps } from '../../../types/popover';

export const POPOVER_OVERLAY_DATA_TEST_ID = 'popover-overlay';

const Overlay: FC<PopoverOverlayProps> = ({
  id,
  className,
  show,
  title,
  content,
  target,
  placement,
  closeButton,
  rootClose,
  onShow,
  onClick,
}) => {
  const getPopover = (): JSX.Element => (
    <Popover data-testid={POPOVER_OVERLAY_DATA_TEST_ID} className={className} id={id}>
      <Popover.Header>
        {!isEmpty(title) && (
          <div className="row align-items-center justify-content-between g-0">
            <div className="col-auto">{title}</div>
            {closeButton && <FontAwesomeIcon className="close-icon col-auto" icon={faXmark} onClick={onShow} />}
          </div>
        )}
      </Popover.Header>
      <Popover.Body>
        {content}

        {closeButton && (
          <div className="row btn-container justify-content-center g-0">
            <Button
              ariaLabel="Close Popover"
              size="sm"
              variant="outline-secondary"
              className="col-auto"
              color="dark-green"
              ctaData={null}
              onClick={onClick}
            >
              Close
            </Button>
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <BSOverlay show={show} target={target} placement={placement} onHide={onShow} rootClose={rootClose}>
      {getPopover()}
    </BSOverlay>
  );
};

export default memo(Overlay);
