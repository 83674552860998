import { Utils } from '../utils';
import 'jquery-colorbox/jquery.colorbox';
import { trackLogin } from '../analytics/general';

const modeHandlers = {
  prices: () => {
    $('.signup-message').html('<b>To enable Price Alerts</b> you need to sign up / login. Please do so below:').show();
  },
  custom_recomm: () => {
    $('.signup-message')
      .html('<b>To enable Personal Recommendation</b> you need to sign up / login. Please do so below:')
      .show();
  },
  review: () => {
    $('h4.signup-title').html('Sign in to post your review.');
    $('.lbl-email .help-popover')
      .data(
        'content',
        "We'll never spam you, but we need to know you're authentic. " +
          "We'll use this address to notify you of important activity that you specify in your email settings " +
          '(eg, when the company responds to your review).',
      )
      .show();
    $('.lbl-name .help-popover')
      .data('content', 'Showing your real first name adds authenticity and trustworthiness to your review. ')
      .show();
    $('.lbl-name span:first-child').text('First Name');
    $('.lbl-zip_code .help-popover')
      .data(
        'content',
        'Showing your home town next to your first name adds authenticity and trustworthiness to your review.',
      )
      .show();
  },
  claim: () => {
    $('h4.signup-title').html('Sign in with your email address to claim your business profile.');
    $('.lbl-email .help-popover')
      .data(
        'content',
        "We'll use this address to notify you of important activity that you specify in your email settings" +
          '(eg, new prospects or reviews). ' +
          "You'll use this address when signing in to securely access your business profile.",
      )
      .show();
    $('.lbl-name .help-popover')
      .data(
        'content',
        "As an official business representative, we'll show your name and company " +
          'next to any answers or comments you post on the site.',
      )
      .show();
    $('.lbl-zip_code .help-popover')
      .data('content', 'Showing your home town on your profile adds authenticity and trustworthiness. ')
      .show();
    $('input[name=usernames]').attr('placeholder', 'Full Name');
    $('input[name=password]').attr('placeholder', 'min 6 characters');
    $('.p_preview_username').hide();
  },
  question: () => {
    $('h4.signup-title').html('Sign in with your email address to be notified of new answers. ');
    $('.lbl-email .help-popover')
      .data('original-title', 'Why ask for your email?')
      .data('content', 'To notify you of new answers. You can unsubscribe any time. ')
      .show();
    $('.lbl-name .help-popover')
      .data('original-title', 'Why ask for your name?')
      .data(
        'content',
        'Showing your real first name adds authenticity to your post and gives people a way to address you.',
      )
      .show();
    $('.lbl-name span:first-child').text('First Name');
    $('.lbl-zip_code .help-popover')
      .data(
        'content',
        'Showing your home town gets your post more relevant and useful responses from experts and other users.',
      )
      .show();
  },
  report: () => {
    $('h4.signup-title')
      .css('font-size', '15px')
      .html(
        'This advice report is private to __name__ and friends.<br/>Sign in with your email address to access it.'.replace(
          /__name__/,
          $('body').data('friend-name'),
        ),
      );
  },
};

const defaultLoginboxOptions = {
  inline: true,
  speed: 150,
  top: '120px',
  width: '570px',
  href: '#account-modal',
  next: '',
  mode: '',
  option: '',
  model: '',
  slug: '',
  onOpen: (e) => {
    $('.signup-message').hide();
    $('#account-modal-onestep').show();
    $('.account-modal-more-toggle').show();
    $('.account-modal-more').hide();
    const $body = $('body');
    const modeHnd =
      $body.data('signup-mode') || $(e.currentTarget).data('signup-mode') || window.location.hash.substr(1);
    if (typeof modeHnd !== 'undefined' && modeHnd && modeHnd in modeHandlers) {
      modeHandlers[modeHnd]();
      $body.data('signup-mode', modeHnd);
    }
    // eslint-disable-next-line
    loginboxDisplayed = true;
  },
  // eslint-disable-next-line
  onClosed: () => (loginboxDisplayed = false),
};

// loginbox opens by default with standard set of rules.
window.loginbox_options = defaultLoginboxOptions;

function successfulLogin(msg, redirect) {
  const loc = window.location;

  if (loc.href.indexOf('getDetails') !== -1) {
    $('#account-modal-onestep .left-block, #account-modal-onestep .login_buttons').hide();
    $('#more_details').show();
    return;
  }

  try {
    trackLogin('Direct', msg.account_type, msg.has_taken_quiz);
  } catch (ex) {
    console.error(ex);
  }

  if (!redirect) return;

  if (msg.next) {
    // this changes location to a new value or forces reload for the same url
    const a = document.createElement('a'); // WTF ?
    a.href = msg.next;
    if (window.location.href === a.href) {
      window.location.reload(true);
    } else {
      window.location.href = a.href;
    }
  } else {
    // to reload page poping up user specific info and prompt for password saving
    $('#account-modal-onestep form').submit(); // so why not just reload page ?
  }
}

const getUrlParams = (signinBox) => {
  let params = `?next=${encodeURIComponent(
    // eslint-disable-next-line
    signinBox.data('js-next') || loginbox_options.next || window.location.href
  )}`;
  const args = ['option', 'model', 'slug'];
  let value;

  args.forEach((i) => {
    const name = args[i];
    // eslint-disable-next-line
    value = loginbox_options[name];
    if (value) params += `&${name}=${value}`;
  });
  return params;
};

export default function OnestepSigninBox(container, options) {
  const self = this;
  const djs = Utils.datajs;

  this.container = $(container);
  this.emailBox = this.container.find(djs('email-box'));
  this.registerBox = this.container.find(djs('register-box'));
  this.loginBox = this.container.find(djs('login-box'));
  this.verificationBox = this.container.find(djs('verification-box'));
  this.onestepBtn = this.container.find(djs('onestep'));
  this.signupMode = $('body').data('signup-mode');
  this.signinTypeEl = this.container.find('#signin_type');
  this.signinTypeEl = this.signinTypeEl.length
    ? this.signinTypeEl
    : $('<input id="signin_type" type="hidden" name="signin_type" />').appendTo(this.loginBox);
  this.options = $.extend(
    {},
    {
      oneStepDone: () => self.onestepBtn.hide(),
      redirect: true,
    },
    options || {},
  );

  this.onRegister = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }

    const button = $(e.currentTarget);
    button.attr('disabled', 'disabled').addClass('disabled working');
    self.container.find('.err-msg').text('');
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `/ajax_register/${getUrlParams(container)}`,
      data: {
        email: self.container.find(djs('signin-email')).val(),
        password: self.container.find(djs('signin-password')).val(),
        name: self.container.find(djs('signin-name')).val(),
        zip_code: self.container.find(djs('signin-zipcode')).val(),
        mode: $('body').data('signup-mode'),
      },
    }).done((msg) => {
      if (msg.status === 'OK') {
        self.container.trigger('register', msg);
        successfulLogin(msg, self.options.redirect);
      } else {
        $.each(msg.errors, (index, value) => $(`.lbl-${index} .err-msg`).text(value[0]));
        button.removeAttr('disabled').removeClass('disabled working');
      }
    });
  };

  this.onLogin = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    const button = $(e.currentTarget);
    button.attr('disabled', 'disabled').addClass('disabled working');
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `/ajax_login/${getUrlParams(container)}`,
      data: {
        email: self.container.find(djs('signin-email')).val(),
        password: self.container.find(djs('signin-password')).val(),
      },
      success: (msg) => {
        if (msg.status === 'OK') {
          self.container.trigger('login', msg);
          $('[data-js="forgot-password"]').hide();
          $('[data-js="login-success"]').show();
          successfulLogin(msg, self.options.redirect);
        } else {
          self.container.find(djs('signin-errors')).show().delay(2000).slideUp();
          $.colorbox.resize();
          setTimeout(() => $.colorbox.resize(), 2700);
          button.removeAttr('disabled').removeClass('disabled working');
        }
      },
      error() {
        button.removeAttr('disabled').removeClass('disabled working');
        // eslint-disable-next-line
        alert('Internal server error. Please try again.');
      },
    });
  };

  this.showOption = (mode, data) => {
    const email = self.container.find(djs('signin-email')).val();
    const registerBtn = self.container.find('[data-js-register]');
    if (mode === 'password_required') {
      self.signinTypeEl.val('login');
      self.container
        .find("[data-js='forgot-password']")
        .attr('href', `/account/password/reset/?email=${email}`)
        .end()
        .find("[data-js='signup-title']")
        .text('Please enter your password to access your account.')
        .end();
      if (typeof data !== 'undefined' && data.first_name) {
        self.container.find("[data-js='login-heading']").text(`Welcome back, ${data.first_name}`).end();
      }
      self.loginBox.show().find(djs('signin-password')).focus().removeAttr('disabled').removeClass('disabled').end();
      self.registerBox.hide();
      $.colorbox.resize();
      // IE < 9 does not support event delegation
      self.container.find('[data-js-login]').removeAttr('disabled').show().off('click').on('click', self.onLogin);

      // Creating new account (registering)
    } else if (mode === 'details_required') {
      self.signinTypeEl.val('register');
      if (self.signupMode === 'claim') {
        self.container.find("[data-js='login-box']").css({ 'margin-top': 0 }).show().end();
        // eslint-disable-next-line
        loginBox.hide();
      }
      self.registerBox.show();
      // IE < 9 does not support event delegation
      registerBtn.removeAttr('disabled').show().off('click').on('click', self.onRegister);

      if (self.options.autoRegister) {
        registerBtn.trigger('click');
      }

      $.colorbox.resize();

      // Passwordless login. Inform about the verification email sent.
    } else if (mode === 'verification_sent') {
      self.signinTypeEl.val('toverify');
      self.registerBox.hide();
      self.emailBox.hide();
      self.container.find("[data-js='login-heading']").text('Welcome back!').end();
      self.verificationBox.show().find("[data-js='user-email']").text(email).end();
    } else if (mode === 'OK') {
      successfulLogin(data, self.options.redirect);
    } else {
      self.container.find("[data-js='signin-errors']").show().delay(2000).slideUp();
      $.colorbox.resize();
      return false;
    }
    return true;
  };

  // eslint-disable-next-line
  this.onOneStep = function (e) {
    if ($(e.currentTarget).is(Utils.datajs('disabled', 'onestep'))) {
      return true;
    }
    const email = self.container.find(djs('signin-email')).val();
    $(e.currentTarget).attr('disabled', 'disabled');

    // Learn about email: is it existing/new? Passwordy/passwordless?
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `/ajax_onestep/${getUrlParams(container)}`,
      data: {
        email,
      },
    }).done((msg) => {
      self.onestepBtn.removeAttr('disabled');
      if (self.showOption(msg.status)) {
        self.onestepBtn.off('click', self.onOneStep);
      }
      // Password required: ask for password: show + highlight field
      if (self.options.oneStepDone) {
        self.options.oneStepDone(msg);
      }
    });
    e.stopImmediatePropagation();
    e.preventDefault();
  };

  this.onestepBtn.on('click', this.onOneStep);

  $('#account-modal-form').submit((e) => {
    // Submitting auth form should perform specific action,
    // handled by a visible 'Signin' button.
    e.preventDefault();
    $(e.currentTarget)
      .find(`${djs('auth-btn')}:visible`)
      .trigger('click');
  });
}

document.addEventListener('DOMContentLoaded', () => {
  (() => {
    const djs = Utils.datajs;

    $('.login-btn')
      .click(() => {
        // eslint-disable-next-line
        loginbox_options = defaultLoginboxOptions;
      })
      // eslint-disable-next-line
      .colorbox(loginbox_options);

    $("#account-modal-onestep input[name='usernames']").keyup((e) => {
      const names = $(e.currentTarget).val().split(' ');
      const prevHeight = $('.preview_username').height();
      $('.preview_username').text(names[0]);
      $('.preview_username').text(names[0] + (names.length > 1 ? ` ${names[1].substr(0, 1)}` : ''));
      if ($('.preview_username').height() !== prevHeight) {
        $.colorbox.resize();
      }
    });

    $(`${djs('signin-box')}, ${djs('true', 'signin-box')}`).each((idx, element) => {
      // eslint-disable-next-line
      new OnestepSigninBox($(element));
    });

    const loc = window.location;
    // when a page is invoked with ?doSignin, pop up the dialog automatically
    if (loc.href.indexOf('doSignin') !== -1) {
      // cleanse url, for beauty and to not pass this parameter on
      if (window.history.replaceState) window.history.replaceState(null, document.title, loc.pathname);
      $('.login-btn').first().click();
    }

    // Facebook started adding #_=_ to the returning URL. Why, Facebook, why?
    // https://developers.facebook.com/blog/post/552/
    if (loc.hash === '#_=_')
      if (window.history.replaceState)
        // for those with History API
        window.history.replaceState(null, document.title, loc.pathname);
      else loc.hash = ''; // for poor IE
  })();
});
