'use strict';

export function ServiceWorkerSetup (csrf_cookie) {
  let exports = {};

  const encode_key = function(key) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
  };

  let _exports = {
    // NOTE: after initialization or subscription are made,
    // values of this variable mean the following:
    // `undefined` - we should not attempt to do actions again.
    //     either service workers are not supported, or user blocked permission.
    // `false` - means user is not subscribed yet or simply closed the permission
    //     request. Can be request again.
    // `true` - user is successfully subscribed.
    is_push_enabled: undefined,

    update_status: function(subscription, status_type, extra_data) {
      // update server with subscription info.
      var request = new XMLHttpRequest();
      // but but but
      var base_url = '/api/v2/notifications/subscription/';
      var method = {
        subscribe: 'POST',
        unsubscribe: 'DELETE'
      }[status_type];

      var payload = {
        endpoint: subscription.endpoint,
        browser: navigator.userAgent,
        auth: encode_key(subscription.getKey('auth')),
        p256dh: encode_key(subscription.getKey('p256dh')),
      };
      if (extra_data) {
        for (var key in extra_data) {
          payload[key] = extra_data[key];
        }
      }

      request.open( method, base_url);
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('X-CSRFToken', csrf_cookie);
      request.send(JSON.stringify(payload));
    }.bind(exports),

    // Once the service worker is registered set the initial state
    initialize_state: function(reg) {
      var self = this;
      self.is_push_enabled = undefined;

      console.log('Initializing state.');

      // Are Notifications supported in the service worker?
      if (!(reg.showNotification)) {
        console.log('Notifications aren\'t supported on service workers.');
        return;
      }

      // Check the current Notification permission.
      // If its denied, it's a permanent block until the
      // user changes the permission
      if (Notification.permission === 'denied') {
        console.log('The user has blocked notifications.');
        return;
      }

      // Check if push messaging is supported
      if (!('PushManager' in window)) {
        console.log('Push messaging isn\'t supported.');
        return;
      }
      // We need the service worker registration to check for a subscription
      return navigator.serviceWorker.ready.then(function(reg) {
        // Do we already have a push message subscription?
        return reg.pushManager.getSubscription()
          .then(function(subscription) {
            self.subscription = subscription;

            if (!subscription) {
              console.log('Not yet subscribed to Push');
              // We aren't subscribed to push, so set UI
              // to allow the user to enable push
              self.is_push_enabled = false;
            } else {
              self.is_push_enabled = true;
              console.log('Already subscribed to Push');
            }
          }).catch(function(err) {
            self.is_push_enabled = false;
            console.log('Error during getSubscription()', err);
          });
      });
    }.bind(exports),

    subscribe: function(extra_data) {
      var self = this;
      console.log('Subscribing');

      return navigator.serviceWorker.ready.then(function(reg) {
        return reg.pushManager.subscribe({userVisibleOnly: true})
          .then(function(subscription) {
            self.subscription = subscription;

            // The subscription was successful
            self.is_push_enabled = true;
            //
            // Update status to subscribe current user on server, and to let
            // other users know this user has subscribed
            self.update_status(subscription, 'subscribe', extra_data);
          })
          .catch(function(e) {
            if (Notification.permission === 'denied') {
              // The user denied the notification permission which
              // means we failed to subscribe and the user will need
              // to manually change the notification permission to
              // subscribe to push messages
              self.is_push_enabled = undefined;
              console.log('Permission for Notifications was denied');

            } else {
              self.is_push_enabled = false;
              // A problem occurred with the subscription, this can
              // often be down to an issue or lack of the gcm_sender_id
              // and / or gcm_user_visible_only
              console.log('Unable to subscribe to push.', e);
            }
          });
      });
    }.bind(exports),

    unsubscribe: function() {
      var self = this;
      if (!self.subscription || !self.subscription.unsubscribe) {
        return;
      }

      return self.subscription.unsubscribe().then(function(event) {
        self.subscription = undefined;
        console.log('Unsubscribed');
      }).catch(function(error) {
        console.log('Unable to unsubscribe', error);
      });
    }.bind(exports),

    init: function() {
      var self = this;
      if (!('serviceWorker' in navigator)) {
        console.log('Service workers aren\'t supported in this browser.');
        return;
      }

      if (window.Notification == null) {
        console.log('Notifications aren\'t supported in this browser.');
        return;
      }

      Notification.requestPermission();
      console.log('Service Workers are supported');

      return navigator.serviceWorker.register('/sw.js').then(function(reg) {
        if(reg.installing) {
          console.log('Service worker installing');
        } else if(reg.waiting) {
          console.log('Service worker installed');
        } else if(reg.active) {
          console.log('Service worker active');
        }
        return self.initialize_state(reg);
      });
    }.bind(exports),

    init_and_subscribe: function(extra_data) {
      var promise = this.init(),
        self = this;
      if (promise !== undefined) {
        return promise.then(function(subscription) {
          if (self.is_push_enabled === false) {
            return self.subscribe(extra_data);
          }
        });
      }
    }.bind(exports),

    init_and_unsubscribe: function() {
      var promise = this.init(),
        self = this;
      if (promise !== undefined) {
        return promise.then(function(subscription) {
          if (self.subscription) {
            return self.unsubscribe();
          }
        });
      }
    }.bind(exports)
  };

  for (var attrname in _exports) {
    exports[attrname] = _exports[attrname];
  }
  return exports;
}
