import { CashbackCodeAction, PromoType } from 'datalayer-service/src/types/enums';
import DataLayerService from 'datalayer-service/src/datalayer';

const dataLayerService = new DataLayerService(window);

export default function enableShowCodeButton() {
  // The enableShowCodeButton method could be called more than one time,
  // so this line unbind previous assignation of on click
  const showCodeBtn = $('[data-show-code]');

  showCodeBtn.off('click');
  showCodeBtn.on('click', (e) => {
    const $this = $(e.currentTarget);

    const promotionId = $this.data('promotion');
    const outboundUrl = $this.data('outboundUrl');
    const objectSlug = $this.data('objectSlug');
    const objectClassname = $this.data('objectClassname');
    const claimable = $this.data('claimable');
    const store = $this.data('storeSlug');
    const retailer = $this.data('retailerSlug');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const source = urlSearchParams.get('ga');

    const cashbackInteractionEvent = {
      action: CashbackCodeAction.CLICK,
      retailerLocation: store,
      retailerName: retailer,
      source,
      pageRegion: $this.data('pageRegion'),
      type: claimable ? PromoType.LOCAL : PromoType.ONLINE,
    };

    dataLayerService.retailerCashbackCodeInterationEvent(cashbackInteractionEvent);

    if (claimable) {
      return;
    }

    e.preventDefault();

    let url = window.location.pathname;
    url = `${url}?show-promo-code=${promotionId}&slug=${objectSlug}&classname=${objectClassname}&scrollY=${window.scrollY}&v=1`;
    url = `${url}${window.location.hash}`;

    dataLayerService.retailerCashbackCodeInterationEvent({
      ...cashbackInteractionEvent,
      action: CashbackCodeAction.VIEW,
      type: PromoType.ONLINE,
    });

    window.open(url);
    window.location.href = outboundUrl;
  });
}
