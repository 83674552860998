import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/js/src/modal';
import DataLayerService from 'datalayer-service';
import { OnlinePromoAction, OnlinePromoEngagementType, PromoType } from 'datalayer-service/src/types/enums';
import { getParameterByName } from '../../../utils';
import { handleComponentUnmount } from '../../utils';

const ONLINE_PROMO_MODAL_ID = 'online-promo-modal-box';

const placeholder = document.createElement('div');
placeholder.setAttribute('id', ONLINE_PROMO_MODAL_ID);
const dataLayerService = new DataLayerService(window);

class OnlinePromoModalBoxComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: { slug: '', name: '' },
      promotion: { code: '', link_only: false, retailer: {} },
      codeCopied: false,
      showCopyNotSupported: false,
      copySupported: props.copySupported,
    };
    this.modal = React.createRef();

    this.dismiss = this.dismiss.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  componentDidMount() {
    const { isBrandPromo, classname, slug, offer, scrollY } = this.props;

    const base = `/api/v2/promos/mattress-deals/`;
    const extraParams = isBrandPromo ? `is_bp=${isBrandPromo}` : `classname=${classname}&slug=${slug}`;
    const url = `${base}${offer}/?${extraParams}`;

    $.get(url, (result) => {
      this.setState({
        product: result,
        promotion: result.extra_data.promotion,
      });
      $(this.modal.current).modal();
      this.trackPromoRevealEvent();
    });

    if (window.history !== undefined && typeof window.history.pushState === 'function') {
      window.history.pushState(null, '', window.location.href.split('?')[0]);
      window.scrollTo(0, scrollY);
    }
  }

  handleCopy(event) {
    event.preventDefault();
    $(event.target).select();

    const { copySupported } = this.state;

    if (copySupported) {
      try {
        const copied = document.execCommand('copy');
        if (copied) {
          this.setState({ codeCopied: true });
        } else {
          this.setState({
            codeCopied: false,
            showCopyNotSupported: true,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  dismiss() {
    const { unmount } = this.props;
    unmount();
  }

  trackPromoRevealEvent() {
    const { classname, promotion } = this.state;
    let engagementType;
    switch (classname) {
      case 'store':
      case 'retailer':
        engagementType = OnlinePromoEngagementType.RETAILER;
        break;
      case 'mattress':
      case 'mattresscollection':
      case 'collection':
      case 'line':
      case 'manufacturer':
        engagementType = OnlinePromoEngagementType.PRODUCT;
        break;
      default:
        engagementType = '';
    }

    dataLayerService.onlinePromotionInteractionEvent({
      action: OnlinePromoAction.PROMO_REVEAL,
      retailer: promotion.retailer.slug,
      engagementType,
      type: PromoType.ONLINE,
    });
  }

  render() {
    const { codeCopied, copySupported, promotion, product, showCopyNotSupported } = this.state;

    return (
      <div
        data-promo-code-reveal={promotion.id}
        className="modal hide fade promo-code-reveal"
        ref={this.modal}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <header className="modal-header text-left">
              <img src={promotion.retailer.logo} alt={`${product.name} logo`} />

              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.dismiss}>
                <span aria-hidden="true">&times;</span>
              </button>
            </header>

            <section className="modal-body text-center">
              {promotion.link_only ? (
                <>
                  <div className="d-flex justify-content-center">
                    <a
                      className="claim-discount-link-btn"
                      href={promotion.outbound_url}
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      Claim this discount
                    </a>
                  </div>
                  <h4 className="modal-title mb-3">
                    Click the button above to activate this discount on the{' '}
                    <a href={promotion.outbound_url} target="_blank" rel="nofollow noreferrer">
                      {promotion.external_domain}
                    </a>
                    . This discount will be applied automatically at checkout (no promo code needed).
                  </h4>
                </>
              ) : (
                <>
                  <div className="box discount">
                    <h5 className="mb-0">
                      <input
                        id={`${product.slug}-promo`}
                        data-promo-input="true"
                        value={promotion.code}
                        onMouseDown={this.handleCopy}
                        readOnly
                      />
                    </h5>
                  </div>
                  <h4 className="modal-title mb-3">
                    <span className={codeCopied ? 'd-block-inline' : 'd-none'}>
                      The code has been copied. Go to&nbsp;
                    </span>
                    <span className={showCopyNotSupported ? 'd-block-inline' : 'd-none'}>
                      Your browser doesn&rsquo;t support this action.
                      <br />
                      Please copy the code above, then go to&nbsp;
                    </span>
                    <span className={codeCopied ? 'd-none' : 'd-block-inline'}>
                      <span className={copySupported ? 'd-none' : 'd-block'}>Copy the code above,</span>
                      <span className={copySupported ? 'd-block' : 'd-none'}>Click the code above to copy it,</span>
                      then go to&nbsp;
                    </span>
                    <a href={promotion.outbound_url} target="_blank" rel="nofollow noreferrer">
                      <b>{promotion.external_domain}</b>
                    </a>{' '}
                    and enter this code at checkout.
                  </h4>
                </>
              )}

              <div className={`box box-gray mt-4 text-sm ${promotion.reward_description ? 'd-block' : 'd-none'}`}>
                Get {promotion.reward_description}
              </div>
            </section>

            <footer className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-default btn-lg center-block"
                onClick={this.dismiss}
                data-dismiss="modal"
              >
                <b>Close</b>
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

function isCopyOperationSupported() {
  let isCopySupported = false;
  if (window.document.queryCommandSupported === undefined) {
    isCopySupported = false;
  } else {
    isCopySupported = window.document.queryCommandSupported('copy');
  }

  return isCopySupported;
}

export default function promoBoxTrigger() {
  // On startup, check if the coupon should be opened
  const showOffer = getParameterByName('show-promo-code');
  const slug = getParameterByName('slug');
  const classname = getParameterByName('classname');
  const isBrandPromo = getParameterByName('is_bp') || false;
  const scrollY = getParameterByName('scrollY');
  const version = getParameterByName('v');

  if (showOffer !== undefined && version === '1' && ((slug && classname) || isBrandPromo)) {
    const copySupported = isCopyOperationSupported();
    document.body.appendChild(placeholder);

    const root = createRoot(placeholder);

    root.render(
      <OnlinePromoModalBoxComponent
        offer={showOffer}
        slug={slug}
        classname={classname}
        isBrandPromo={isBrandPromo}
        scrollY={scrollY}
        copySupported={copySupported}
        unmount={() => {
          handleComponentUnmount(ONLINE_PROMO_MODAL_ID, root);
        }}
      />,
    );
  }
}
