import 'noty/js/noty/jquery.noty';

$(function() {
    var dupes = {};
    var messages = [];

    // Filter duplicated messages.
    $.each(all_messages, function(i, el) {
        if (el.message && !dupes[el.message]) {
            dupes[el.message] = true;
            messages.push(el);
        }
    });

    $.each(messages, function(index, el) {
        var noty_type = {
            'info'   : 'information',
            'warning': 'warning',
            'error'  : 'error',
            'success': 'success',
            'debug'  : 'alert'
        };

        noty({
            text: el.message,
            dismissQueue: true,
            layout: 'top',
            type: el.tags ? noty_type[el.tags] : 'information',
            closeWith: ['click', 'button']
        });
    });


    // What is this?
    // The same question.
    if (typeof(notify) !== 'undefined') {
        var element = $('#validate_email_alert');
        element.click(function(){notify.close(true);});
        notify.show();
    }
});
