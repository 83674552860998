import React, { FC, useState } from 'react';
import { ErrorMessage, Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import * as Yup from 'yup';
import { faMobile } from '@fortawesome/pro-light-svg-icons';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import BsForm from 'react-bootstrap/Form';
import isEmpty from 'lodash/isEmpty';
import Input from '../Input';
import { PHONE } from '../../../constants/signin';
import Button from '../Button';
import {
  SEND_DEALS_DISCLAIMER,
  SEND_DEALS_MULTI_DEALS_BTN_TEXT,
  SEND_DEALS_SINGLE_DEAL_BTN_TEXT,
} from '../../molecules/PromoModal/SendDeals';
import { Deal, SaveDealItem } from '../../../types/deal';
import { formatDataToSaveDeal } from '../../../utils/saveDealsService';
import { PromotionType } from '../../../types/promotion';
import { User } from '../../../types/user';
import { phoneRegExp } from '../../../utils/regex-validations';

export type SaveDealsFormFormikProps = {
  name: string;
  phone: string;
  email: string;
  deals: SaveDealItem[];
};

export type SaveDealsFormProps = {
  isMultiDeal?: boolean;
  pageRegion: PageRegion;
  user?: User | null;
  deal?: Deal;
  saveDealItems?: SaveDealItem[];
  phoneLabel?: string;
  nameLabel?: string;
  emailLabel?: string;
  onSubmit: (values: SaveDealsFormFormikProps) => void;
};

const SignupSchema = Yup.object().shape({
  name: Yup.string(),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Invalid email.'),
});

export const SAVE_DEALS_FORM_TEST_ID = 'SAVE_DEALS_FORM_TEST_ID';

const SaveDealsForm: FC<SaveDealsFormProps> = ({
  user = null,
  pageRegion,
  isMultiDeal = false,
  saveDealItems = [],
  deal,
  onSubmit,
  emailLabel = 'Email this info to me',
  nameLabel = 'Name',
  phoneLabel = 'Text (SMS) this info to my phone',
}) => {
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      validationSchema={SignupSchema}
      validateOnChange={validateOnChange}
      validateOnBlur
      initialValues={{
        name: user?.first_name || '',
        phone: '',
        email: user?.email || '',
        deals: saveDealItems,
      }}
      onSubmit={(values): void => {
        if (values.email || values.phone) {
          onSubmit(values);
        }
      }}
    >
      {({ values, submitCount, handleChange, setFieldValue, isValid }): JSX.Element => (
        <>
          <Form data-tesid={SAVE_DEALS_FORM_TEST_ID} className="save-deal-form">
            <div className="save-deal-form__fields">
              <div className="save-deal-form__fields__item">
                <label htmlFor="email">{nameLabel}</label>
                <Input type="text" name="name" placeholder="First name" value={values.name} onChange={handleChange} />
                <ErrorMessage component="div" name="name" className="error-message" />
              </div>
              <div className="save-deal-form__fields__item">
                <label htmlFor="phone">{phoneLabel}</label>
                <Input
                  type="tel"
                  name={PHONE}
                  placeholder="(555) 555 - 5555"
                  value={values.phone}
                  prependIcon={false}
                  icon={<FontAwesomeIcon icon={faMobile} />}
                  onChange={handleChange}
                />
                <ErrorMessage component="div" name="phone" className="error-message" />
              </div>
              <div className="save-deal-form__fields__item">
                <label htmlFor="email">{emailLabel}</label>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={values.email}
                  prependIcon={false}
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  onChange={handleChange}
                />
                <ErrorMessage component="div" name="email" className="error-message" />
              </div>
            </div>

            {isMultiDeal && (
              <div className="send-deals__checkboxes">
                <div className="send-deals__checkboxes-container__title">Send me these deals:</div>
                <div className="send-deals__checkboxes-container">
                  {deal &&
                    deal.deals.map((d, index) => {
                      const saveDealItemIdx =
                        values &&
                        values.deals &&
                        values.deals.findIndex((dl: SaveDealItem) => dl.retailer === d.retailer.id);

                      return (
                        <BsForm.Check id={d.retailer.slug} key={d.retailer.slug}>
                          <BsForm.Check.Input
                            id={`${d.retailer.slug}-checkbox-input`}
                            type="checkbox"
                            name={`deals.${index}`}
                            value={d.retailer.id}
                            checked={saveDealItemIdx > -1}
                            onChange={(e: React.ChangeEvent<any>): void => {
                              const { checked } = e.target;

                              if (checked) {
                                const currentDeal = deal.deals.find((ds) => ds.retailer.id === d.retailer.id);

                                if (currentDeal)
                                  setFieldValue('deals', [...values.deals, formatDataToSaveDeal(currentDeal)]);
                              } else if (!checked)
                                setFieldValue(
                                  'deals',
                                  values.deals.filter((s: SaveDealItem) => s.retailer !== d.retailer.id),
                                );
                            }}
                          />
                          <BsForm.Check.Label>
                            Save at {d.retailer.name}{' '}
                            {d.main_promotion_type === PromotionType.AFFILIATE_REBATE ? 'online' : ''}
                          </BsForm.Check.Label>
                        </BsForm.Check>
                      );
                    })}
                </div>
              </div>
            )}

            <Button
              className="email-authentication__btn"
              ariaLabel="Send Deal"
              type="submit"
              color="dark-blue"
              ctaData={{
                category: CTAClickCategory.SIGNIN,
                level: CTAClickLevel.PRIMARY,
                url: '',
                pageRegion,
              }}
              onClick={() => setValidateOnChange(true)}
              disabled={!isValid || (isMultiDeal && values && values.deals?.length === 0)}
            >
              {isMultiDeal ? SEND_DEALS_MULTI_DEALS_BTN_TEXT : SEND_DEALS_SINGLE_DEAL_BTN_TEXT}
            </Button>

            {values && isEmpty(values.email) && isEmpty(values.phone) && submitCount > 0 && (
              <div data-testid="error-message-email-phone}" className="error-message" style={{ textAlign: 'center' }}>
                Whoops! Enter your phone and/or email.
              </div>
            )}
          </Form>
          {!isEmpty(values.phone) && <div className="send-deals__disclaimer">{SEND_DEALS_DISCLAIMER}</div>}
        </>
      )}
    </Formik>
  );
};

export default SaveDealsForm;
