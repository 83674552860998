/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const ArrowDoodleIcon: FC<IconProps> = ({ className = 'arrow-doodle' }) => (
  <svg
    className={className}
    width="218"
    height="28"
    viewBox="0 0 218 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0979904 3.31015C1.09052 3.36641 1.6631 3.45975 2.17639 3.41442C6.4778 3.1305 199.5 3.14304 202.949 5.78789C204.5 6.50015 206.081 7.56632 206.789 8.65508C209.136 12.3332 211.252 16.4559 211.002 21.0829C209.959 20.4555 209.816 19.3649 209.325 18.5928C208.884 17.8642 208.49 17.1315 207.965 16.5064C207.804 16.2807 207.231 16.1874 206.96 16.3073C206.688 16.4272 206.403 16.9321 206.429 17.2176C206.543 17.9751 206.703 18.7284 206.999 19.4217C207.952 21.688 208.994 23.8985 209.989 26.1131C210.743 27.7254 211.417 27.9057 212.733 26.8302C214.885 25.1051 216.384 22.9101 217.285 20.3362C217.434 19.8913 217.294 19.376 217.282 18.7055C215.084 19.3793 215.159 21.8191 213.415 22.8366C213.386 21.9757 213.369 21.2576 213.353 20.5396C213.356 16.3181 211.346 12.3299 209.372 8.61882C208.021 6.09989 205.071 4.25735 202.48 3.14304C199.997 1.50005 7.83187 0.955631 4.81602 0.88618C3.30599 0.827657 1.74929 1.77325 0.0979904 3.31015Z"
      fill="#6281B8"
    />
  </svg>
);

export default ArrowDoodleIcon;
