import React, { FC } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { getUrlAsDisplayText } from '../../../utils/urls';

export type RedeemLinkProps = {
  linkUrl: string;
  isLoading: boolean;
  // This is used for PostHogFeatureFlags.SIDEBAR_WIDTH_PROFILE_PAGE. Can be removed after experiment is done.
  onLinkClick?: () => void;
};

export const DATA_TEST_REDEEM_LINK = 'data-test-redeem-link';

const RedeemLink: FC<RedeemLinkProps> = ({ linkUrl, isLoading, onLinkClick }) => {
  return (
    <div className="share-ui__redeem-link" data-testid={DATA_TEST_REDEEM_LINK}>
      <span>Redeem after purchase:</span>{' '}
      <ReactPlaceholder
        style={{ marginTop: 0, maxWidth: '200px', display: 'inline-block' }}
        type="textRow"
        ready={!isLoading}
        showLoadingAnimation
      >
        <a
          href={`https://${linkUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e): void => {
            e.stopPropagation();

            if (onLinkClick) onLinkClick();
          }}
        >
          {getUrlAsDisplayText(linkUrl)}
        </a>
      </ReactPlaceholder>
    </div>
  );
};

export default RedeemLink;
