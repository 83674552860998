import DataLayerService from 'datalayer-service/src/datalayer';
import { isMobile } from '../utils';

const dataLayerService = new DataLayerService(window);

export function trackSearchSubmitEvent(e) {
  const element = $(e.target);

  dataLayerService.siteSearchEvent({
    term: element.find('search_term').val().trim() || element.find('q').val().trim(),
    type: 'custom term',
  });
}

export function trackSearchEvent() {
  const $inputs = $('.search-text');

  $inputs.each((idx, input) => {
    const $input = $(input);
    let resultIndexes = {};
    let lastResults = [];
    // eslint-disable-next-line
    let term = '';

    $input
      .typeahead({
        menu: '<ul id="search-term_menu" class="typeahead dropdown-menu"></ul>',
        source: (query, process) =>
          $.get('/autocomplete/universal_search/', { q: query }, (data) => {
            resultIndexes = {};
            lastResults = data;
            term = query;

            for (let i = 0; i < data.length; i++) {
              resultIndexes[data[i].result] = i;
            }

            return process(data.map((item) => item.result));
          }),
        sorter: (items) => items,
        updater: (item) => {
          $input.val(item);
          $input.closest('form').submit();
          return item;
        },
        minLength: 2,
        items: isMobile ? 10 : 35,
      })
      .closest('form')
      // eslint-disable-next-line
      .on('submit', () => {
        const val = $input.val().trim();
        const index = resultIndexes[val];

        if (lastResults[index]) {
          const searchType = lastResults[index].type;

          dataLayerService.siteSearchEvent({
            term: val,
            type: lastResults[index].type,
            resultManufacturer: searchType === 'manufacturer' ? val : '',
          });
        }

        $input.val(val);
        if (!val) {
          return false;
        }
      });
  });
}
