import React, { FC } from 'react';
import ExpandBox, { OnToggleFnType } from '../../atoms/ExpandBox';
import PromoBox from '../../atoms/PromoBox';
import RedeemLink from '../../atoms/RedeemLink';
import { PromotionType } from '../../../types/promotion';

export type RebatePromoExpandProps = {
  redeemLink: string;
  isRedeemLinkLoading: boolean;
  title: string;
  subTitle: string;
  retailerName: string;
  promotionType: PromotionType;
  endDate?: string;
  toggleable?: boolean;
  onToggle: OnToggleFnType;
};

export const DATA_TEST_REBATE_PROMO_EXPAND_CONTENT = 'rebate-promo-expand-content-test-id';
export const DATA_TEST_REBATE_PROMO_EXPAND_HEADER = 'rebate-promo-expand-header-test-id';

const RebatePromoExpand: FC<RebatePromoExpandProps> = ({
  redeemLink,
  isRedeemLinkLoading,
  title,
  subTitle,
  retailerName,
  promotionType,
  endDate,
  toggleable = true,
  onToggle,
}) => {
  const isAffiliatePartner = promotionType === PromotionType.AFFILIATE_REBATE;
  const boxSubHeader = (): JSX.Element => {
    return (
      <div className="shared-ui__promo-expand-box__rebate" data-testid={DATA_TEST_REBATE_PROMO_EXPAND_HEADER}>
        <PromoBox title={title} subTitle={subTitle} ribbonVariant="extra" />
        {toggleable && (
          <span className="shared-ui__promo-expand-box__rebate__redeem-here">
            <RedeemLink linkUrl={redeemLink} isLoading={isRedeemLinkLoading} />
          </span>
        )}
      </div>
    );
  };

  return (
    <ExpandBox
      title="Cash Back from GoodBed"
      subHeaderElement={boxSubHeader()}
      toggleable={toggleable}
      onToggle={onToggle}
    >
      <div className="shared-ui__promo-expand-box__content" data-testid={DATA_TEST_REBATE_PROMO_EXPAND_CONTENT}>
        <p className="shared-ui__promo-expand-box__content__description">
          {isAffiliatePartner ? (
            <>
              After purchasing, use the personal link above to claim your extra Cash Back from GoodBed. Valid on any
              qualifying {retailerName} purchase over $599.
            </>
          ) : (
            <>
              After purchasing, use this personal link to claim your extra Cash Back.{' '}
              {endDate && <>Redeem by {endDate}.</>}
            </>
          )}
        </p>
      </div>
    </ExpandBox>
  );
};

export default RebatePromoExpand;
