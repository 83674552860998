import React, { memo, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isString from 'lodash/isString';
import classnames from 'classnames';
import Async from 'react-select/async';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { components, DropdownIndicatorProps, SingleValue, PropsValue } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import Button from '../../atoms/Button';
import { ObjectLookup } from '../../../types/commons';
import fetch, { ApiVersion, FetchParams } from '../../../utils/fetchService';

// TODO formatting autocomplete results
const objectLookupToAsyncOption = (v: ObjectLookup[]): Option[] =>
  v.map((i) => ({
    value: i.id,
    label: i.name,
  }));

export type Option = {
  label: string | JSX.Element;
  img?: string;
  description?: string;
  value: number | string;
  slug?: string;
  id?: number | string;
  classname?: string;
  name?: string;
};

type AsyncSelectState = {
  items: any[];
  isLoading: boolean;
};

export type AddSelectOption = {
  value: 'empty_value' | 'new_model_name' | 'new_model_trigger' | 'add_new_brand';
  label: string;
  isDisabled?: boolean;
};

type AsyncSelectProps = {
  className?: string;
  wrapperClassname?: string;
  name: string;
  placeholder?: string;
  url: string;
  query?: FetchParams;
  inputValueQueryParam?: string;
  value?: string | number;
  inputValue: string;
  minLength?: number;
  apiVersion?: ApiVersion;
  defaultOptions?: Option[];
  maxMenuHeight?: number;
  pageRegion: PageRegion;
  cacheOptions?: boolean;
  closeMenuOnSelect?: boolean;
  isDisabled?: boolean;
  searchIcon?: boolean;
  hideDropDown?: boolean;
  clearable?: boolean;
  shouldResetOnSelect?: boolean;
  optionsFormatter?(v: ObjectLookup[]): Option[] | [];
  onInputChange(newValue: string, meta: Record<'action', string>): void;
  onChange?(OnChangeParams: any): void;
  onClear?(): void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  addNewOpt?: AddSelectOption[];
  controlValue?: PropsValue<Option> | null;
  noOptionsMessage?: any;
  menuPortalTarget?: HTMLElement | null;
  menuPortalStyles?<T>(base: T): T;
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>): JSX.Element | null => {
  return (
    // eslint-disable-next-line
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSearch} />
    </components.DropdownIndicator>
  );
};

const AsyncSelect: React.FunctionComponent<AsyncSelectProps> = ({
  className,
  wrapperClassname,
  name,
  placeholder = 'Start typing',
  url,
  query = {},
  inputValueQueryParam = 'q',
  value,
  inputValue,
  minLength = 3,
  apiVersion = 2,
  defaultOptions = [],
  maxMenuHeight = 400,
  pageRegion,
  cacheOptions = false,
  searchIcon = false,
  clearable = true,
  shouldResetOnSelect = false,
  closeMenuOnSelect = false,
  isDisabled = false,
  optionsFormatter,
  onInputChange,
  onChange,
  onClear,
  onBlur,
  addNewOpt,
  autoFocus = false,
  controlValue = null,
  noOptionsMessage,
  menuPortalTarget,
  menuPortalStyles = (o): any => o,
}: AsyncSelectProps) => {
  const inputRef: any = React.createRef();
  const [currentInputValue, setCurrentInputValue] = useState<string>(inputValue);
  const [selectValue, setSelectValue] = useState<PropsValue<Option> | null>(controlValue);
  const [data, setData] = useState<AsyncSelectState>({
    items: defaultOptions,
    isLoading: false,
  });

  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    if (!isEmpty(defaultOptions)) {
      setData({ items: defaultOptions, isLoading: false });
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (!isEmpty(data.items) && !isEmpty(value)) {
      const item = find(data.items, { value });

      if (item) {
        setCurrentInputValue(item.value.split('-').join(' '));
      }
    }
  }, [data, value]);

  const handleClearInput = (): void => {
    setCurrentInputValue('');
    setSelectValue(null);

    if (onClear) onClear();
  };

  const handleChange = (selected: SingleValue<Option>): void => {
    if (onChange && selected) {
      onChange({
        target: {
          name,
          value: selected.value,
          label: selected.label,
          brandClassName: selected.classname,
          brandName: selected.name,
        },
      });
      if (shouldResetOnSelect) handleClearInput();
    }
  };

  const formatAsyncOptions = (items: any[]): Option[] => {
    const opts = optionsFormatter ? optionsFormatter(items) : objectLookupToAsyncOption(items);

    if (addNewOpt) {
      for (let i = 0; i < addNewOpt.length; i += 1) {
        opts.push(addNewOpt[i]);
      }
    }

    return opts;
  };

  // Syncronize input state.
  useEffect(() => {
    setCurrentInputValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!isEmpty(defaultOptions)) {
      setData({ items: defaultOptions, isLoading: false });
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (!isEmpty(data.items) && !isEmpty(value)) {
      const item = find(data.items, { value });

      if (item) {
        setCurrentInputValue(item.value.split('-').join(' '));
      }
    }
  }, [data, value]);

  const isThereInputValue = !!currentInputValue;

  // eslint-disable-next-line consistent-return
  const promiseOptions = async (newInputValue: string): Promise<any> => {
    if (!isEmpty(newInputValue) && newInputValue.length >= minLength) {
      setData({
        items: [],
        isLoading: true,
      });

      try {
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        const { signal } = controllerRef.current;

        const newInputQueryParamValue = query[inputValueQueryParam] || newInputValue;

        const items =
          (await fetch.get<any[]>(
            url,
            { ...query, [inputValueQueryParam]: newInputQueryParamValue },
            { signal },
            apiVersion,
          )) || [];

        setData({
          items,
          isLoading: false,
        });

        return formatAsyncOptions(items);
      } catch (error) {
        // Aborting the fetch call provoques a DOMException.
        if (error instanceof DOMException) {
          return formatAsyncOptions(data.items);
        }

        setData({
          items: [],
          isLoading: false,
        });
      }
    }
  };

  return (
    <div className={classnames('async-select-field-wrapper', wrapperClassname)}>
      <Async
        components={searchIcon ? { DropdownIndicator } : undefined}
        ref={inputRef}
        isDisabled={isDisabled}
        className={classnames('async-select-input', className)}
        classNamePrefix="async-select"
        id={name}
        name={name}
        placeholder={placeholder}
        inputValue={currentInputValue}
        value={selectValue}
        isLoading={data.isLoading}
        loadOptions={promiseOptions}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        onInputChange={onInputChange}
        onChange={handleChange}
        menuIsOpen={isThereInputValue}
        closeMenuOnSelect={closeMenuOnSelect}
        captureMenuScroll={false}
        maxMenuHeight={maxMenuHeight}
        onBlur={onBlur}
        autoFocus={autoFocus}
        menuPortalTarget={menuPortalTarget}
        styles={{ menuPortal: (base): any => menuPortalStyles({ ...base, zIndex: 9999 }) }}
        noOptionsMessage={noOptionsMessage}
      />
      {clearable
        ? ((isString(currentInputValue) && currentInputValue.length > 0) ||
            (isString(inputValue) && inputValue.length > 0)) && (
            <Button
              className="clear-btn"
              ariaLabel="Clear button"
              tabIndex={-1}
              ctaData={{
                category: CTAClickCategory.RESET_ASYNC_SELECT,
                level: CTAClickLevel.PRIMARY,
                url: '',
                pageRegion,
              }}
              onClick={handleClearInput}
            >
              <FontAwesomeIcon className="async-select-clear" icon={faXmark} />
            </Button>
          )
        : null}
    </div>
  );
};

export default memo(AsyncSelect);
