/*
  Add on this enum the feature flags provided by the backend.
*/
export enum FeatureFlags {
  COLLECT_MORE_PHONE_NUMBERS_V1 = 'COLLECT_MORE_PHONE_NUMBERS_V1',
  RETAILER_DASHBOARD_REQUEST_SETTINGS = 'RETAILER_DASHBOARD_REQUEST_SETTINGS',
  RETAILER_DASHBOARD_DISPLAY_SETTINGS = 'RETAILER_DASHBOARD_DISPLAY_SETTINGS',
  RETAILER_DASHBOARD_PRODUCT_LOOKUP = 'RETAILER_DASHBOARD_PRODUCT_LOOKUP',
  RETAILER_DASHBOARD_API_SETTINGS = 'RETAILER_DASHBOARD_API_SETTINGS',
  HEADER_SHOW_POPULAR_COMPARISONS = 'HEADER_SHOW_POPULAR_COMPARISONS',
}

export const FEATURE_FLAGS_URL = '/feature-flags/get-feature-flag/';

/*
  PostHog Feature Flags
*/
export enum PostHogFeatureFlags {
  /*
    CTA Deal Button - this experiment has been stopped on 11/15/2024
    https://us.posthog.com/project/33085/experiments/57077
  */
  CTA_DEAL_BTN = 'cta-deal-btn', // https://us.posthog.com/project/33085/feature_flags/76493
  PROMO_BOX_DESIGN = 'promo-box-design', // https://us.posthog.com/project/33085/feature_flags/17111
  PROMO_SEND_DEALS = 'promo-send-deals', // https://us.posthog.com/project/33085/feature_flags/78351
  SIDEBAR_WIDTH_PROFILE_PAGE = 'sidebar-width-profile-page', // https://us.posthog.com/project/33085/feature_flags/78968
  SIDEBAR_STRIKE_OUT_PRICE = 'sidebar-strike-out-price', // https://us.posthog.com/project/33085/feature_flags/83940
}

export enum PostHogFeatureFlagVariantKeys {
  CONTROL = 'control',
  TEST = 'test',
}

export enum PostHogExperimentEvents {
  /*
    CTA Deal Button - this experiment has been stopped on 11/15/2024
    https://us.posthog.com/project/33085/experiments/57077
  */
  PROMO_BUTTON_CLICKED = 'promo-button-clicked',
  // Event used on Promo box desgin experiment (https://us.posthog.com/project/33085/experiments/4584)
  PRODUCT_DISCOUNT_BOX_CLICKED = 'product-discount-box-clicked',
  // Event used on Promo send deals experiment (https://us.posthog.com/project/33085/experiments/58795)
  PROMO_SEND_DEALS_CLICKED = 'promo-send-deals-clicked',
  // Events used on Local Deals sidebar width variant experiment (https://us.posthog.com/project/33085/experiments/59631)
  LOCAL_REBATE_BEST_DEAL_CLICKED = 'local-rebate-best-deal-clicked',
  LOCAL_REBATE_BEST_DEAL_START_REDEEM = 'local-rebate-best-deal-start-redeem',
  LOCAL_REBATE_BEST_DEAL_FINISHED_REDEEM = 'local-rebate-best-finished-redeem',
  // Events used on Sidebar strike out price experiment (https://us.posthog.com/project/33085/experiments/64458)
  DEAL_CLICKED = 'deal-clicked',
}
