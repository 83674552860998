import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import DataLayerService from 'datalayer-service';
import { CashbackCodeAction } from 'datalayer-service/src/types/enums';
import { renderModal } from '../modals/general-promo-modal';
import { BRAND_PROMO, RETAILER_PROMO } from '../constants';
import {
  LDP_METRICS_STORE_LOCATOR_PAGE,
  LDP_METRICS_TRY_THIS_BED_PAGE,
  LDP_METRICS_OTHER_PAGE,
  LDP_METRICS_PROFILE_PAGE,
  LDP_METRICS_STORE_CLASSNAME,
  LDP_METRICS_RETAILER_CLASSNAME,
  LDP_METRICS_MANUFACTURER_CLASSNAME,
} from '../../../constants';

export const ID = 'promo-btn';

const dataLayerService = new DataLayerService(window);

function PromoButton({
  type,
  storeSlug,
  storeName,
  retailerName,
  retailerSlug,
  productName,
  title,
  titleShort,
  description,
  promotionID,
  logo,
  externalURL,
  externalDomain,
  outboundURL,
  locationPage,
  objectClassname,
  isLDP,
  showIcon,
  dismiss,
  onClick,
  onHide,
}) {
  const handlePromoClick = () => {
    renderModal({
      type,
      storeSlug,
      storeName,
      retailerName,
      retailerSlug,
      productName,
      title,
      description,
      promotionID,
      externalURL,
      externalDomain,
      outboundURL,
      locationPage,
      objectClassname,
      isLDP,
      dismiss,
      onHide,
    });

    if (onClick) onClick();

    dataLayerService.retailerCashbackCodeInterationEvent({
      action: CashbackCodeAction.CLICK,
      retailerName,
      retailerLocation: storeSlug,
      type,
    });
  };

  return (
    <button
      type="button"
      className="compact-promo-box promotion-btn"
      data-dismiss="modal"
      // eslint-disable-next-line
      rel="nofollow"
      onClick={handlePromoClick}
    >
      {titleShort}
      {showIcon && <i className="fa-regular fa-info-circle" />}
      {logo && <img alt={retailerName} style={{ marginLeft: '10px' }} src={logo} />}
    </button>
  );
}

PromoButton.defaultProps = {
  productName: '',
  description: '',
  promotionID: null,
  logo: '',
  externalURL: '',
  outboundURL: '',
  locationPage: undefined,
  objectClassname: undefined,
  isLDP: false,
  showIcon: true,
  dismiss: true,
  onClick: undefined,
  onHide: undefined,
};

PromoButton.propTypes = {
  type: PropTypes.oneOf([BRAND_PROMO, RETAILER_PROMO]).isRequired,
  storeSlug: PropTypes.string,
  storeName: PropTypes.string.isRequired,
  retailerName: PropTypes.string.isRequired,
  retailerSlug: PropTypes.string,
  productName: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleShort: PropTypes.string.isRequired,
  description: PropTypes.string,
  promotionID: PropTypes.number,
  logo: PropTypes.string,
  externalURL: PropTypes.string,
  externalDomain: PropTypes.string,
  outboundURL: PropTypes.string,
  locationPage: PropTypes.oneOf([
    LDP_METRICS_STORE_LOCATOR_PAGE,
    LDP_METRICS_TRY_THIS_BED_PAGE,
    LDP_METRICS_OTHER_PAGE,
    LDP_METRICS_PROFILE_PAGE,
  ]),
  objectClassname: PropTypes.oneOf([
    LDP_METRICS_MANUFACTURER_CLASSNAME,
    LDP_METRICS_STORE_CLASSNAME,
    LDP_METRICS_RETAILER_CLASSNAME,
  ]),
  isLDP: PropTypes.bool,
  showIcon: PropTypes.bool,
  dismiss: PropTypes.bool,
  onClick: PropTypes.func,
  onHide: PropTypes.func,
};

export default PromoButton;

export function renderPromoButton(selector = `[id=${ID}]`) {
  const promoInstances = Array.from(document.querySelectorAll(selector));

  promoInstances.forEach((element) => {
    const type = element.getAttribute('data-type');
    const storeSlug = element.getAttribute('data-store-slug');
    const storeName = element.getAttribute('data-store-name');
    const retailerName = element.getAttribute('data-retailer-name');
    const retailerSlug = element.getAttribute('data-retailer-slug');
    const productName = element.getAttribute('data-product-name');
    const title = element.getAttribute('data-title');
    const titleShort = element.getAttribute('data-title-short');
    const description = element.getAttribute('data-description');
    const promotionID = element.getAttribute('data-promotion-id');
    const logo = element.getAttribute('data-logo');
    const externalURL = element.getAttribute('data-external-url');
    const externalDomain = element.getAttribute('data-external-domain');
    const outboundURL = element.getAttribute('data-outbound-url');
    const locationPage = element.getAttribute('data-location-page');
    const objectClassname = element.getAttribute('data-object-classname');
    const isLDP = element.getAttribute('data-ldp');
    const showIcon = element.getAttribute('data-show-icon');
    const dismiss = element.getAttribute('data-dismiss');

    const root = createRoot(element);

    root.render(
      <PromoButton
        type={type}
        storeSlug={storeSlug}
        storeName={storeName}
        retailerName={retailerName}
        retailerSlug={retailerSlug}
        productName={productName}
        title={title}
        titleShort={titleShort}
        description={description}
        promotionID={promotionID ? parseInt(promotionID, 10) : null}
        logo={logo}
        externalURL={externalURL}
        externalDomain={externalDomain}
        outboundURL={outboundURL}
        locationPage={locationPage}
        objectClassname={objectClassname}
        isLDP={isLDP ? isLDP === 'True' : false}
        showIcon={showIcon ? showIcon === 'true' : true}
        dismiss={dismiss ? dismiss === 'true' : true}
      />,
    );
  });
}
