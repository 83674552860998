import React, { FC } from 'react';
import Accordion from '../Accordion';

type IncognitoAccordionProps = {
  smoothChevronIcon?: boolean;
};

const IncognitoAccordion: FC<IncognitoAccordionProps> = ({ smoothChevronIcon = false }) => {
  return (
    <div className="incognito-accordion">
      <Accordion id="incognitoAccordion" smoothChevronIcon={smoothChevronIcon}>
        {[
          {
            eventKey: 'iphone-ipad',
            title: 'Iphone or iPad',
            description: '',
            element: (
              <>
                <div className="incognito-accordion__content-list-title">Chrome:</div>
                <ul>
                  <li>
                    Tap the <b>three dots …</b> icon (in lower right).
                  </li>
                  <li>
                    Select <b>New Incognito tab.</b>
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Safari:</div>
                <ul>
                  <li>
                    Tap on <b>Tabs</b> icon{' '}
                    <img
                      alt="Safari mobile tabs icon"
                      src="/static/images/safari-mobile-tabs-icon.svg"
                      width={14}
                      height={14}
                    />{' '}
                    (two boxes, in lower right).
                  </li>
                  <li>
                    Tap <b>[number] Tabs</b> to show the Tab Groups list.
                  </li>
                  <li>
                    Select <b>Private</b> to show currently open private tabs
                  </li>
                  <li>
                    Tap the <b>plus sign +</b> icon to open a new Private tab.
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Firefox:</div>
                <ul>
                  <li>Tap Tabs icon (a number inside a rectangle, in lower right).</li>
                  <li>
                    Tap the <b>Zoro mask</b> icon near the top.
                  </li>
                  <li>
                    Tap <b>+ Private</b> button to add a new private tab.
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Microsoft Edge:</div>
                <ul>
                  <li>
                    Tap on the <b>3 horizontal lines</b> icon (in lower right).
                  </li>
                  <li>
                    Select <b>New InPrivate tab</b>.
                  </li>
                </ul>
              </>
            ),
          },
          {
            eventKey: 'android-phone-tablet',
            title: 'Android Phone / Tablet',
            description: '',
            element: (
              <>
                <div className="incognito-accordion__content-list-title">Chrome:</div>
                <ul>
                  <li>
                    Tap the <b>three dots …</b> icon (in upper right).
                  </li>
                  <li>
                    Select <b>New Incognito tab.</b>
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Firefox:</div>
                <ul>
                  <li>Tap Tabs icon (a number inside a rectangle, in lower right).</li>
                  <li>
                    Tap the <b>Zoro mask</b> icon near the top.
                  </li>
                  <li>
                    Tap <b>+ Private</b> button to add a new private tab.
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Microsoft Edge:</div>
                <ul>
                  <li>
                    Tap the <b>three dots …</b> icon.
                  </li>
                  <li>
                    Select <b>New InPrivate tab</b>.
                  </li>
                  or
                  <li>
                    Tap on the <b>Tabs</b> icon.
                  </li>
                  <li>
                    Switch to the <b>InPrivate</b> tab.
                  </li>
                  <li>
                    Tap the Plus <b>plus +</b> icon at the bottom to open a new Private tab
                  </li>
                </ul>
              </>
            ),
          },
          {
            eventKey: 'computer',
            title: 'Computer (Laptop or Desktop)',
            description: '',
            element: (
              <>
                <div className="incognito-accordion__content-list-title">Chrome:</div>
                <ul>
                  <li>
                    Go to <b>File</b> menu &#x2192; Select <b>New Incognito Window</b>.
                  </li>
                  or
                  <li>Use keyboard shortcut:</li>
                  <ul>
                    <li>
                      <b>Ctrl+Shift+N</b> (Windows).
                    </li>
                    <li>
                      <b>Command+Shift+N</b> (macOS).
                    </li>
                  </ul>
                </ul>

                <div className="incognito-accordion__content-list-title">Safari:</div>
                <ul>
                  <li>
                    Go to <b>File</b> menu &#x2192; Select <b>New Incognito Window</b>.
                  </li>
                  or
                  <li>
                    Use keyboard shortcut <b>Command+Shift+N</b>.
                  </li>
                </ul>

                <div className="incognito-accordion__content-list-title">Firefox:</div>
                <ul>
                  <li>
                    Go to <b>File</b> menu &#x2192; Select <b>New Incognito Window</b>.
                  </li>
                  or
                  <li>Use keyboard shortcut:</li>
                  <ul>
                    <li>
                      <b>Ctrl+Shift+P</b> (Windows).
                    </li>
                    <li>
                      <b>Command+Shift+P</b> (macOS).
                    </li>
                  </ul>
                </ul>

                <div className="incognito-accordion__content-list-title">Microsoft Edge:</div>
                <ul>
                  <li>
                    Click the <b>three dots …</b> icon (in upper right).
                  </li>
                  <li>
                    Select <b>New InPrivate Window</b> from the drop-down menu.
                  </li>
                </ul>
              </>
            ),
          },
        ]}
      </Accordion>
    </div>
  );
};

export default IncognitoAccordion;
