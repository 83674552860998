// Mattress Reviews & Comparisons modal
import React from 'react';
import PropTypes from 'prop-types';
import BasePromoModal from './base-promo-modal';
import PromoButton from '../buttons/general';
import { PROMOTION, BRAND_PROMO, RETAILER_PROMO, REBATE_PROMOTION } from '../constants';
import CashbackPromoButton from '../buttons/cashback';
import {
  LDP_METRICS_STORE_LOCATOR_PAGE,
  LDP_METRICS_TRY_THIS_BED_PAGE,
  LDP_METRICS_OTHER_PAGE,
  LDP_METRICS_PROFILE_PAGE,
  LDP_METRICS_STORE_CLASSNAME,
  LDP_METRICS_RETAILER_CLASSNAME,
  LDP_METRICS_MANUFACTURER_CLASSNAME,
} from '../../../constants';

class SaleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimablePromoIndex: undefined,
      claimablePromoID: undefined,
    };

    this.handlePromoClick = this.handlePromoClick.bind(this);
  }

  componentDidMount() {
    const { promotions } = this.props;

    if (promotions) {
      const idx = promotions.findIndex((promo) => promo.classname === REBATE_PROMOTION);

      if (idx > -1) {
        this.setState({ claimablePromoID: promotions[idx].id });
        this.setState({ claimablePromoIndex: idx });
      }
    }
  }

  handlePromoClick() {
    const { onHide } = this.props;

    onHide();
  }

  renderModalBody() {
    const { claimablePromoID, claimablePromoIndex } = this.state;
    const {
      storeSlug,
      storeName,
      retailerName,
      promotions,
      locationPage,
      objectClassname,
      manufacturerId,
      isLDP,
      onShow,
    } = this.props;

    return (
      <>
        <p>
          {retailerName} is currently running the following promotions. Click on any promotion to see more information
          about it. We recommend contacting the store to confirm details and expiration.
        </p>

        {promotions && (
          <div className="promo-list">
            {claimablePromoID && claimablePromoIndex !== undefined && (
              <CashbackPromoButton
                storeSlug={storeSlug}
                storeName={storeName}
                promotionID={claimablePromoID}
                manufacturerId={manufacturerId}
                showButton
              />
            )}
            {promotions
              .filter((promo) => promo.classname !== REBATE_PROMOTION && promo.classname !== PROMOTION)
              .map((promo, idx) => (
                <PromoButton
                  // eslint-disable-next-line
                  key={`general-promo-${idx}`}
                  type={promo.classname}
                  storeSlug={storeSlug}
                  storeName={storeName}
                  retailerName={retailerName}
                  productName={promo.classname === BRAND_PROMO ? promo.product_name : ''}
                  title={promo.title}
                  titleShort={promo.title_short}
                  description={promo.description}
                  promotionID={claimablePromoID || undefined}
                  logo={promo.logo}
                  externalURL={promo.external_url}
                  externalDomain={promo.external_domain}
                  outboundURL={promo.outbound_url}
                  locationPage={locationPage}
                  objectClassname={objectClassname}
                  isLDP={isLDP}
                  dismiss={false}
                  onClick={this.handlePromoClick}
                  onHide={onShow}
                />
              ))}
          </div>
        )}
      </>
    );
  }

  render() {
    const {
      storeSlug,
      storeName,
      retailerName,
      externalURL,
      outboundURL,
      onHide,
      externalDomain,
      locationPage,
      objectClassname,
      isLDP,
    } = this.props;

    return (
      <BasePromoModal
        className="sale-modal"
        title="Available Discounts"
        subtitle={retailerName}
        body={this.renderModalBody()}
        storeSlug={storeSlug}
        storeName={storeName}
        retailerName={retailerName}
        externalURL={externalURL}
        externalDomain={externalDomain}
        outboundURL={outboundURL}
        locationPage={locationPage}
        objectClassname={objectClassname}
        isLDP={isLDP}
        onHide={onHide}
      />
    );
  }
}

SaleModal.propTypes = {
  storeSlug: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  retailerName: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      classname: PropTypes.oneOf([BRAND_PROMO, RETAILER_PROMO, PROMOTION]).isRequired,
      description: PropTypes.string,
      product_name: PropTypes.string,
      retailer_name: PropTypes.string,
      store_name: PropTypes.string,
      title: PropTypes.string.isRequired,
      title_short: PropTypes.string.isRequired,
    }),
  ),
  externalURL: PropTypes.string,
  outboundURL: PropTypes.string,
  externalDomain: PropTypes.string,
  locationPage: PropTypes.oneOf([
    LDP_METRICS_STORE_LOCATOR_PAGE,
    LDP_METRICS_TRY_THIS_BED_PAGE,
    LDP_METRICS_OTHER_PAGE,
    LDP_METRICS_PROFILE_PAGE,
  ]),
  objectClassname: PropTypes.oneOf([
    LDP_METRICS_STORE_CLASSNAME,
    LDP_METRICS_RETAILER_CLASSNAME,
    LDP_METRICS_MANUFACTURER_CLASSNAME,
  ]),
  manufacturerId: PropTypes.number,
  isLDP: PropTypes.bool,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SaleModal;
