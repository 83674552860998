import qs from 'qs';
import { ParsedUrlQuery } from 'querystring';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { filterObject, formatObjectToArray } from './collections';
import {
  DYNAMIC_PARAMS,
  BASE_SEARCH_FIELD,
  ORDER_BY_FIELD,
  SEARCH_FIELD,
  SEPARATOR,
  MOST_RECENT_VALUE,
} from '../constants/review-summary';
import { FILTER_PAGE_KEY } from '../constants/filter';
import { ReviewSummaryStateType } from '../types/review-summary';

export const REVIEWS_LIST_ID = 'reviews-list';

export function getNewItemToReplace(queryString: string, stringifyItem: string, matchedIdx: number): string {
  let newItem = '';
  const qsHasAnd = queryString.indexOf('&') > -1;
  const matchedItemhasPreAnd = queryString.substring(matchedIdx - 1, matchedIdx) === '&';

  if (qsHasAnd && matchedItemhasPreAnd) {
    newItem = `&${stringifyItem}`;
  } else if (qsHasAnd && !matchedItemhasPreAnd) {
    newItem = `${stringifyItem}&`;
  } else newItem = stringifyItem;

  return newItem;
}

function getOldItem(queryString: string, matchedIdx: number): string {
  let item = '';
  if (matchedIdx === -1) return item;

  const nextAmpersandIndex = queryString.indexOf('&', matchedIdx);

  if (nextAmpersandIndex > -1) {
    item = queryString.substring(matchedIdx, nextAmpersandIndex);
  } else {
    item = queryString.substring(matchedIdx, queryString.length);
  }

  return item;
}

function getMatchedIdx(queryString: string, stringifyItem: string, item: any): number {
  let field = '';

  if (has(item, ORDER_BY_FIELD)) {
    field = `${ORDER_BY_FIELD}=`;
  } else if (has(item, SEARCH_FIELD)) {
    field = `${SEARCH_FIELD}=`;
  } else field = stringifyItem;

  return queryString.indexOf(field);
}

function getFieldQS(
  queryString: string,
  stringifyItem: string,
  item: any,
  matchedIdx: number,
  doesExist: boolean,
): string {
  let newQueryString = queryString;

  const oldItem = getOldItem(queryString, matchedIdx);

  if (
    (has(item, ORDER_BY_FIELD) && isEmpty(item[ORDER_BY_FIELD])) ||
    (has(item, SEARCH_FIELD) && isEmpty(item[SEARCH_FIELD]))
  ) {
    const extra = newQueryString.substring(matchedIdx - 1, matchedIdx);
    const replaceItem = extra === '?' ? oldItem : `${extra}${oldItem}`;
    newQueryString = newQueryString.replace(replaceItem, '');
  } else if (doesExist) {
    newQueryString = newQueryString.replace(oldItem, stringifyItem);
  } else if (isEmpty(newQueryString)) {
    newQueryString = `${stringifyItem}`;
  } else newQueryString = `${newQueryString}&${stringifyItem}`;

  return newQueryString;
}

function getFilterQS(queryString: string, stringifyItem: string, matchedIdx: number, doesExist: boolean): string {
  let newQueryString = queryString;

  if (doesExist) {
    const replace = getNewItemToReplace(queryString, stringifyItem, matchedIdx);
    return newQueryString.replace(replace, '');
  }
  if (isEmpty(newQueryString)) newQueryString = `${stringifyItem}`;
  else newQueryString = `${newQueryString}&${stringifyItem}`;

  return newQueryString;
}

export function getQuerystring(queryString: string, item: any): string {
  const stringifyItem = !isEmpty(item)
    ? qs.stringify(item, {
        arrayFormat: 'repeat',
        indices: false,
      })
    : '';
  const matchedIdx = getMatchedIdx(queryString, stringifyItem, item);
  const isSpecialField = has(item, ORDER_BY_FIELD) || has(item, SEARCH_FIELD);

  const doesExistInQS = matchedIdx > -1;

  return isSpecialField
    ? getFieldQS(queryString, stringifyItem, item, matchedIdx, doesExistInQS)
    : getFilterQS(queryString, stringifyItem, matchedIdx, doesExistInQS);
}

export function cleanQS(queryString: string): string {
  const idx = queryString.indexOf('&');

  return idx > -1 && (queryString[idx - 1] === '?' || queryString[idx + 1] === '&')
    ? queryString.replace(queryString[idx], '')
    : queryString;
}

export function getNormalizedParams(query: ParsedUrlQuery): ParsedUrlQuery {
  return filterObject(query, (key) => !DYNAMIC_PARAMS.includes(key));
}

export const getFiltersFromQueryParams = (queryParams: ParsedUrlQuery): string[] => {
  // Not include page query param as a filter.
  const queryParamsFilters = filterObject(queryParams, (key) => key !== FILTER_PAGE_KEY);

  return formatObjectToArray(queryParamsFilters).flat(1);
};

const updateWithNewFilter = (filterName: string, filterValue: string, prevFilters: string[]): string[] => {
  let filters = prevFilters;
  const newFilter = `${filterName}${SEPARATOR}${filterValue}`;
  const isSearchInputFilter = filterName === SEARCH_FIELD;
  const isOrderByFilter = newFilter.includes(ORDER_BY_FIELD);

  let currentSearchFilterValue: string | undefined;

  if (isSearchInputFilter) {
    currentSearchFilterValue = filters.find((filter) => filter.includes(BASE_SEARCH_FIELD));
  }

  // Check if new filter is for sorting, if it is, we swap it for the current one.
  if (isOrderByFilter && filters.find((filter) => filter.includes(ORDER_BY_FIELD))) {
    filters = filters.filter((filter) => !filter.includes(ORDER_BY_FIELD));

    // This is the default sorting, so we don't need to push to the filters.
    if (filterValue === MOST_RECENT_VALUE) {
      return filters;
    }
  }

  // Check if filter is already on the state, if it is, remove it.
  if (filters.includes(newFilter) && !isSearchInputFilter) {
    filters = filters.filter((filter) => filter !== newFilter);

    return filters;
  }

  // Check if there is value already in the search input.
  if (currentSearchFilterValue) {
    filters = filters.filter((filter) => filter !== currentSearchFilterValue);

    if (!filterValue) return filters;
  }

  filters = filters.concat(newFilter);

  return filters;
};

export const handleReplaceForNewFilters = (
  state: ReviewSummaryStateType,
  newFilters: string[],
): ReviewSummaryStateType => {
  let filterOrderBy = '';
  let { filters } = state;

  const formatedNewFilters = newFilters.map((filter) => {
    const splitedFilter = filter.split(SEPARATOR);
    let filterName = '';
    let filterValue = '';

    if (splitedFilter.length >= 2) {
      [filterName, filterValue] = splitedFilter;
    }

    if (filterName === ORDER_BY_FIELD) filterOrderBy = filterValue;

    filters = updateWithNewFilter(filterName, filterValue, filters);

    return [filterName, filterValue];
  });

  const forNewQuerystring: {
    [k: string]: any;
  } = {};

  formatedNewFilters.forEach(([filterName, filterValue]) => {
    if (forNewQuerystring[filterName]) forNewQuerystring[filterName].push(filterValue);
    else {
      forNewQuerystring[filterName] = [filterValue];
    }
  });

  // Reset the filters page.
  forNewQuerystring[FILTER_PAGE_KEY] = undefined;

  const newReviews = {
    ...state.reviews,
    [FILTER_PAGE_KEY]: 1,
    querystring: qs.stringify(forNewQuerystring, {
      arrayFormat: 'repeat',
      indices: false,
    }),
  };

  const payload = {
    filters,
    [ORDER_BY_FIELD]: filterOrderBy,
    reviews: newReviews,
    isNextPageLoading: true,
  };

  return payload;
};

export const handleFilterChange = (
  state: ReviewSummaryStateType,
  prevQueryParams: ParsedUrlQuery,
  newFilterName: string,
  newFilterValue: string,
): ReviewSummaryStateType => {
  const queryParams = {
    ...prevQueryParams,
    // Reset the filters page.
    [FILTER_PAGE_KEY]: undefined,
  };

  const newReviews = {
    ...state.reviews,
    [FILTER_PAGE_KEY]: 1,
    querystring: getQuerystring(
      qs.stringify(queryParams, {
        arrayFormat: 'repeat',
        indices: false,
      }),
      {
        [newFilterName]: newFilterValue,
      },
    ),
  };

  const payload = {
    reviews: newReviews,
    filters: state.filters,
    [ORDER_BY_FIELD]: newFilterName === ORDER_BY_FIELD ? newFilterValue : '',
    isNextPageLoading: true,
  };

  payload.filters = updateWithNewFilter(newFilterName, newFilterValue, payload.filters);

  return payload;
};
