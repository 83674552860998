import { Brand } from './brands';
import { MattressStatus } from './commons';
import { MattressModel } from './mattresses';
import { NewPromotion, PromotionType } from './promotion';
import { MapPositionType } from './stores';

export type BrandDeal = {
  id: number;
  classname: string;
  slug: string;
  name: string;
  listed: boolean;
  logo: string;
  get_status_display: MattressStatus;
  is_valid_retailer_partner?: boolean;
};

type RetailerDealNearestStore = {
  id: number;
  name: string;
  slug: string;
  city: string;
  state: string;
  state_abbr: string;
  map_position: MapPositionType;
};

export type RetailerDeal = {
  id: number;
  name: string;
  slug: string;
  logo: string;
  nearest_store?: RetailerDealNearestStore;
};

export type DealItem = {
  description: string;
  hash?: string;
  main_promotion_type: PromotionType;
  brand: BrandDeal;
  retailer: RetailerDeal;
  promotions: NewPromotion[];
};

export type Deal = {
  brand: BrandDeal;
  deals: DealItem[];
};

export type Deals = Deal[];

export type SaveDealPromotion = {
  promotion: number;
  promotion_type: PromotionType;
};

export interface BaseSaveDeal {
  retailers: number[];
}

export type SaveDealItem = {
  brand: number;
  retailer: number;
  promotions: SaveDealPromotion[];
};

export interface SaveDeal {
  email?: string;
  phone?: string;
  name?: string;
  next?: string;
  deals: SaveDealItem[];
}

export interface DealsResult {
  count: number;
  next: null | string;
  previous: null | string;
  results: Deal[];
}

export interface ProductDealResponse {
  brand?: BrandDeal;
  deals?: DealItem[];
  product?: MattressModel | Brand;
}

export enum DealCouponVariantType {
  DEFAULT = 'default',
  BLUE = 'blue',
}

export enum DealCouponPosthogFeatureFlagVariantType {
  CONTROL = 'control',
  TEST = 'test',
}
