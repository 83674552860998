'use client';

import React, { FC, memo, useState } from 'react';
import dynamic from 'next/dynamic';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { BasePopoverProps } from '../../../types/popover';
import { OverlayTriggerType } from '../../atoms/Tooltip';
import PopoverOverlay from '../../atoms/PopoverOverlay';

const DynamicPopoverModal = dynamic(() => import('../PopoverModal'));

export const POPOVER_DATA_TEST_ID = 'popover';

const Popover: FC<BasePopoverProps> = ({
  id,
  className,
  title,
  content,
  placement = 'bottom',
  closeButton = false,
  hideMobileCloseButton = false,
  modal = false,
  textContainerClassName = '',
  children,
  trigger,
  active = true,
}) => {
  if (!active) return children;

  const screenWidth = useWindowWidth();
  const showOverlay = screenWidth > 576 && !modal;
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<any>(null);

  const handleShow = (): void => {
    if (content) setShow(!show);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    handleShow();

    if (!show) setTarget(e.target);
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLButtonElement>, eventTrigger: OverlayTriggerType): void => {
    if (trigger === eventTrigger || trigger?.includes(eventTrigger)) handleShow();

    if (!show) setTarget(e.target);
  };

  return (
    <span data-testid={POPOVER_DATA_TEST_ID} className={`${textContainerClassName}`}>
      {React.cloneElement(children, {
        onClick: handleClick,
        onMouseEnter: (e: React.MouseEvent<HTMLButtonElement>) => handleMouseEvent(e, 'hover'),
        onMouseLeave: (e: React.MouseEvent<HTMLButtonElement>) => handleMouseEvent(e, 'hover'),
        onFocus: (e: React.MouseEvent<HTMLButtonElement>) => handleMouseEvent(e, 'focus'),
      })}

      {showOverlay ? (
        <PopoverOverlay
          id={id}
          className={className}
          show={show}
          title={title}
          content={content}
          target={target}
          placement={placement}
          closeButton={closeButton}
          rootClose
          onShow={handleShow}
          onClick={handleClick}
          trigger={trigger}
        />
      ) : (
        show && (
          <DynamicPopoverModal
            id={id}
            className={className}
            show={show}
            title={title}
            content={content}
            hideMobileCloseButton={hideMobileCloseButton}
            onShow={handleShow}
          />
        )
      )}
    </span>
  );
};

export default memo(Popover);
