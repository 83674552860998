import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import SUReviewSummary, { REVIEW_SUMMARY_ID } from 'shared-ui/src/components/organism/ReviewSummary';
import useFetch from 'shared-ui/src/hooks/useFetch';
import { Mattresses, ReviewsSummary } from 'shared-ui/src/types/review-summary';

interface ReviewSummaryProps {
  classname: string;
  slug: string;
}

const ReviewSummary: FC<ReviewSummaryProps> = ({ classname, slug }) => {
  const summaryDataEndpoint = `/${classname}/${slug}/reviews-summary/`;

  const { data: summaryData } = useFetch<ReviewsSummary>(summaryDataEndpoint);
  const { data: productData } = useFetch<Mattresses>(`/${classname}/${slug}/`);

  if (summaryData && productData)
    return <SUReviewSummary product={productData} summaryData={summaryData} type="profile-page" />;

  return (
    <div className="review-summary-placeholder">
      <div className="review-summary-placeholder__top-info">
        <div className="review-summary-placeholder__top-info__box" />
        <div className="review-summary-placeholder__top-info__text">
          <div className="review-summary-placeholder__top-info__text__title" />
          <div className="review-summary-placeholder__top-info__text__description" />
        </div>
      </div>

      <div className="review-summary-placeholder__summary">
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item large">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
      </div>

      <div className="review-summary-placeholder__ctas">
        <div className="review-summary-placeholder__ctas__button" />
        <div className="review-summary-placeholder__ctas__button" />
      </div>
    </div>
  );
};

export function renderReviewSummary() {
  const element = document.getElementById(REVIEW_SUMMARY_ID);

  if (element) {
    const root = createRoot(element);
    const classname = element.getAttribute('data-classname');
    const slug = element.getAttribute('data-slug');

    if (classname && slug) root.render(<ReviewSummary classname={classname} slug={slug} />);
  }
}

export default ReviewSummary;
