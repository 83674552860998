'use client';

import React, { FC, useEffect, useRef } from 'react';
import cls from 'classnames';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import SmoothChevronIcon from '../../../atoms/Icons/SmoothChevron';
import styles from './ProductsToCompare.module.scss';
import { CompareItem } from '../../../../types/mattress-comparison';
import { ExtraProductCompareItem } from '../CompareItem';
import useCollapse from '../../../../hooks/useCollapse';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import LinkButton from '../../../atoms/LinkButton';
import { MANUFACTURER, MATTRESS, ROUTES } from '../../../../constants/commons';
import useCompareWidgetState from '../store';
import useFetch from '../../../../hooks/useFetch';
import { mapToCompareItem } from '../../../../utils/mattress-comparison';
import MultiCarousel from '../../../molecules/MultiCarousel';
import compareWidgetStyles from '../CompareWidget.module.scss';
import { DEFAULT_API_VERSION } from '../../../../utils/fetchService';

interface CollapsedListProps {
  products: CompareItem[];
  haveProductsLoaded: boolean;
  title: string;
  quizUrl?: string;
  modelsFromQuizResults?: boolean;
  shouldBeCollapsed?: boolean;
}

const CollapsedList: FC<CollapsedListProps> = ({
  products,
  haveProductsLoaded,
  title,
  modelsFromQuizResults,
  quizUrl,
  shouldBeCollapsed = false,
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { toggleCollapse, isCollapsed, isCollapsing, recalculateHeight } = useCollapse(listContainerRef, {
    initialCollapse: shouldBeCollapsed,
  });

  useEffect(() => {
    toggleCollapse(shouldBeCollapsed);
  }, [shouldBeCollapsed]);

  useEffect(() => {
    if (isMobile) toggleCollapse(true);
  }, []);

  useEffect(() => {
    recalculateHeight();
  }, [haveProductsLoaded]);

  const getCollapsedElement = (): JSX.Element => {
    // Case when quiz was not taken.
    if (modelsFromQuizResults && products.length === 0)
      return (
        <div className={styles.quizCollapseElement}>
          <LinkButton
            to={quizUrl || ROUTES.mattressFinderQuiz}
            className={styles.quizCTA}
            ariaLabel="Take the GoodBed Match Quiz"
            variant="quiz-outline"
            target="_blank"
            ctaData={{
              pageRegion: PageRegion.COMPARE_WIDGET,
              category: CTAClickCategory.TAKE_THE_QUIZ,
              level: CTAClickLevel.PRIMARY,
            }}
          >
            <b>Take the GoodBed Quiz</b>
            <p>to compare vs. your best matches</p>
          </LinkButton>
        </div>
      );

    return isMobile ? (
      <ul className={cls(compareWidgetStyles.compareWidgetList, styles.productList)}>
        {products.map((product) => (
          <ExtraProductCompareItem key={`${product.slug}-compare-item`} compareItem={product} />
        ))}
      </ul>
    ) : (
      <MultiCarousel
        className={styles.productsCarousel}
        slidesClassname={styles.productsCarouselItem}
        slidesToScroll={4}
      >
        {products.map((product) => (
          <ExtraProductCompareItem key={`${product.slug}-compare-item`} compareItem={product} />
        ))}
      </MultiCarousel>
    );
  };

  return (
    <div className={cls(styles.compareWidgetExtraProductsItem, { collapsed: isCollapsed && !isCollapsing })}>
      <button
        type="button"
        className={styles.accordionTrigger}
        onClick={() => {
          toggleCollapse();
        }}
      >
        {title}
        <SmoothChevronIcon rotate={isCollapsed ? 270 : 90} />
      </button>

      <div className={styles.collapsedElementContainer} ref={listContainerRef}>
        {getCollapsedElement()}
      </div>
    </div>
  );
};

interface ProductsToCompareProps {
  className?: string;
  productClassname: string;
  productName: string;
  productSlug: string;
  manufacturerSlug: string;
  manufacturerName: string;
  shouldBeCollapsed?: boolean;
  quizUrl?: string; // Quiz Url with quizpath can be provided by Django template in the case of profile pages.
  showSimilarSliderTop?: boolean;
}

const ProductsToCompare: FC<ProductsToCompareProps> = ({
  className = '',
  productClassname,
  productName,
  productSlug,
  manufacturerSlug,
  manufacturerName,
  shouldBeCollapsed = false,
  quizUrl,
  showSimilarSliderTop = false,
}) => {
  const { compareType } = useCompareWidgetState();

  const {
    data: popularProducts,
    isLoading: popularProductsLoading,
    error: popularProductsError,
  } = useFetch<any[]>('/mattress-comparison/popular-products/', { type: compareType });

  const {
    data: quizComparisonData,
    isLoading: quizComparisonDataLoading,
    error: quizComparisonDataError,
  } = useFetch<string | CompareItem[]>('/mattress-comparison/quiz-result/', {
    type: compareType,
  });

  const {
    data: otherModelsData,
    isLoading: otherModelsDataLoading,
    error: otherModelsDataError,
  } = useFetch<any[]>(
    '/mattress-comparison/other-models/',
    {
      type: MANUFACTURER,
      slug: manufacturerSlug!,
      onlymodels: true,
    },
    {
      apiVersion: DEFAULT_API_VERSION,
    },
  );

  const {
    data: similarModelsData,
    isLoading: similarModelsDataLoading,
    error: similarModelsDataError,
  } = useFetch<any[]>(
    '/mattress-comparison/similar-to/',
    {
      slug: productSlug!,
    },
    {
      apiVersion: DEFAULT_API_VERSION,
    },
    Boolean(productClassname === MATTRESS),
  );

  const comparePopularProducts = popularProducts ? popularProducts.map((product) => mapToCompareItem(product)) : [];
  const compareQuizProducts =
    quizComparisonData && Array.isArray(quizComparisonData)
      ? quizComparisonData.map((product) => mapToCompareItem(product))
      : [];
  const otherModelsProducts =
    otherModelsData && Array.isArray(otherModelsData)
      ? otherModelsData.map((product) => mapToCompareItem(product))
      : [];
  const similarModelProducts =
    similarModelsData && Array.isArray(similarModelsData)
      ? similarModelsData.map((product) => mapToCompareItem(product))
      : [];

  const hidePopularProductsSection =
    ((!comparePopularProducts || !comparePopularProducts.length) && !popularProductsLoading) || popularProductsError;

  const hideQuizCompareSection = (!quizComparisonData?.length && !quizComparisonDataLoading) || quizComparisonDataError;

  const hideOtherModelsSection =
    ((!otherModelsProducts || !otherModelsProducts.length) && !otherModelsDataLoading) || otherModelsDataError;

  const hideSimilarModelsSection =
    ((!similarModelProducts || !similarModelProducts.length) && !similarModelsDataLoading) || similarModelsDataError;

  const getSimilarModels = (): JSX.Element => {
    return (
      <CollapsedList
        products={similarModelProducts}
        haveProductsLoaded={similarModelsDataLoading}
        title={`Similar to ${productName}`}
        shouldBeCollapsed={shouldBeCollapsed}
      />
    );
  };

  return (
    <div className={cls(styles.compareWidgetExtraProducts, className)}>
      <div className={styles.compareWidgetExtraProductsContainer}>
        <h3>Add Products to Compare</h3>

        {hideSimilarModelsSection || !showSimilarSliderTop ? null : getSimilarModels()}

        {hidePopularProductsSection ? null : (
          <CollapsedList
            haveProductsLoaded={popularProductsLoading}
            products={comparePopularProducts}
            title="Popular mattress models"
          />
        )}

        {hideOtherModelsSection ? null : (
          <CollapsedList
            products={otherModelsProducts}
            haveProductsLoaded={otherModelsDataLoading}
            title={`Other mattress models from ${manufacturerName}`}
            shouldBeCollapsed={shouldBeCollapsed}
          />
        )}

        {hideSimilarModelsSection || showSimilarSliderTop ? null : getSimilarModels()}

        {hideQuizCompareSection ? null : (
          <CollapsedList
            products={compareQuizProducts}
            haveProductsLoaded={quizComparisonDataLoading}
            title="Recommended beds from your Quiz Result"
            quizUrl={quizUrl}
            modelsFromQuizResults
            shouldBeCollapsed={shouldBeCollapsed}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsToCompare;
