/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';
import { ICON_CLASS } from '../../../constants/commons';

const InfoIcon: FC<IconProps> = ({ color = 'white' }) => (
  <svg
    className={`${ICON_CLASS} info-icon`}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58334 4.50002C8.58334 4.82216 8.32219 5.08331 8.00005 5.08331C7.6779 5.08331 7.41675 4.82216 7.41675 4.50002C7.41675 4.17787 7.6779 3.91672 8.00005 3.91672C8.32219 3.91672 8.58334 4.17787 8.58334 4.50002Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M8.00005 12.0833C7.75855 12.0833 7.56255 11.8873 7.56255 11.6458V7.125H6.97925C6.73775 7.125 6.54175 6.929 6.54175 6.6875C6.54175 6.446 6.73775 6.25 6.97925 6.25H8.00005C8.24155 6.25 8.43755 6.446 8.43755 6.6875V11.6458C8.43755 11.8873 8.24155 12.0833 8.00005 12.0833Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M8 15C4.14005 15 1 11.86 1 8C1 4.14005 4.14005 1 8 1C11.86 1 15 4.14005 15 8C15 11.86 11.86 15 8 15ZM8 1.875C4.62251 1.875 1.875 4.62251 1.875 8C1.875 11.3775 4.62251 14.125 8 14.125C11.3775 14.125 14.125 11.3775 14.125 8C14.125 4.62251 11.3775 1.875 8 1.875Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M9.3125 12.0833H6.6875C6.446 12.0833 6.25 11.8873 6.25 11.6458C6.25 11.4043 6.446 11.2083 6.6875 11.2083H9.3125C9.554 11.2083 9.75 11.4043 9.75 11.6458C9.75 11.8873 9.554 12.0833 9.3125 12.0833Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);

export default InfoIcon;
