import 'jquery-placeholder/jquery.placeholder';
import 'bootstrap/js/dist/tooltip';
import {favorite_btn_ajaxy, setFavTooltips} from './generaleffects-utils';

window.__price_range = [
  0,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  1100,
  1200,
  1300,
  1400,
  1500,
  1600,
  1700,
  1800,
  1900,
  2000,
  2300,
  2600,
  2900,
  3200,
  3600,
  4000,
  4400,
  4800,
  5200,
  5400,
  5600,
  6000,
  6500,
  7000,
  7500,
  8000,
  8500,
  9000,
  9500,
  10000
];

// opens little glossary window
let newwindow;

export function opengloss(url) {
  newwindow = window.open(
    url,
    "name",
    "height=220,width=220,left=800,top=250,scrollbars=yes"
  );
  if (window.focus) {
    newwindow.focus();
  }
}




//////////////////////////// BIG INIT /////////////////////////////
$().ready(function() {
  $(".header-brands-tooltip").tooltip();
  $("#side-menu .header-brands-tooltip").tooltip('disable');

  // handles tooltips
  $("a.tooltip, span.tooltip").tooltip({
    showURL: false,
    simple: true,
    track: true,
    fade: 250
  });

  // nice Abusive box
  $("a.abuse").click(function() {
    $.ajax({
      method: "GET",
      url: $(this).data("href"),
      success: function(resp){
        $("#correctionModalContent").html(resp)
        $("#correctionModal").modal('toggle');
      }});
  });

  // helpfullness Ajaxiness
  // TODO: Find all usages of this code and replace it with the React Component
  $("div.helpful a.rate").click(function() {
    const splitter = new RegExp("helpfulness_(\\S+)_(\\d+)");
    // div > ul > li > a
    const div = $(this).parent().parent().parent()
    const res = splitter.exec(div.attr("id"));
    const model = res[1];
    const id = res[2];
    let span = $(this)
      .parent()
      .parent()
      .parent()
      .children("a")
      .children("span.rating");
    if (span.length === 0) {
      span = $(this)
        .parent()
        .children("span.helpful-count")
        .children("span.rating");
    }
    span.html('<img src="' + MEDIA_URL + 'images/worker.gif" />');
    if ($(this).hasClass("vote-up")) {
      span.load("/helpfulness/" + model + "/" + id + "/yes");
    } else if ($(this).hasClass("vote-down")) {
      span.load("/helpfulness/" + model + "/" + id + "/no");
    }
  });

  $("a#show-change-zip-code-box").click(function(event) {
    event.preventDefault();
    if ($("div#change-zip-code-box").is(":visible")) {
      $("div#change-zip-code-box").hide();
    } else {
      $("div#change-zip-code-box").show();
    }
  });

  // Removes the anchor part of the URL when clicking on a video wrapper link
  // Notable post #337775
  var lastPos = 0;
  $('[href^="#video-wrapper"]').click(function() {
    // need to save the current position, otherwise the page
    // jumps to the top when setting location.hash to ''
    lastPos = $(window).scrollTop();
  });
  $(window).bind("hashchange", function(event) {
    if (window.location.hash.match("^#video-wrapper")) {
      window.location.hash = "";
      $(window).scrollTop(lastPos);
    }
  });

  var favBtns = $(".btn-favorite-group");
  setFavTooltips(favBtns);
  favBtns.mouseup(function(ev) {
    var $this = $(this), $target = $(ev.target);
    if ($this.hasClass("favorited") && $target.hasClass("favorite-text")) {
      window.location = "/favorites/";
    } else {
      favorite_btn_ajaxy(this, null);
    }
  });

  $("a.print-store")
    .attr(
      "data-original-title",
      "Print list of My Saved Beds available in this store"
    )
    .tooltip();

  // init placeholder fallback
  $("input, textarea").placeholder();
});

$(function() {
  // "Read more" links.
  // TODO: Refactor this into a plugin.
  // TODO: Check if `data-original` and `data-excerpt` are empty.
  var $excerpts = $(".excerpt");
  var $readMoreLink = '<a class="read-more">Read more</a>';

  $excerpts.each(function() {
    var $e = $(this),
      original = $e.data("original") || "",
      excerpt = original.split(" ").slice(0, 44).join(" ") + "... ";

    if (original.split(" ").length < 45) {
      $e.html(original);
      return;
    }

    $e.html(excerpt + $readMoreLink);
    $e.find($(".read-more")).click(function() {
      var $link = $(this);

      $link.hide();
      $e.html(original)
    });
  });
});

window.opengloss = opengloss;
