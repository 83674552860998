import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import ReactPlaceholder from 'react-placeholder/lib';
import { isTestEnv, wait } from '../../../utils/commons';
import ClipboardService from '../../../utils/clipboardService';
import styles from './CopyPasteBox.module.scss';
import { getClassNames } from '../../../utils/styles';

export type CopyPasteBoxProps = {
  className?: string;
  text: string;
  url?: string;
  isLoading?: boolean;
  showLinkMode?: boolean;
  successText?: string;
  // This is used for PostHogFeatureFlags.SIDEBAR_WIDTH_PROFILE_PAGE. Can be removed after experiment is done.
  onLinkClick?: () => void;
  onCopy?: () => void;
  onError?: (e: any) => void;
};
export const DATA_TEST_COPY_PASTE_BOX = 'copy-paste-box-test-id';
export const DATA_TEST_COPY_PASTE_BOX_SUCCESS = 'copy-paste-box-test-success';

type CopyPasteBoxState = 'idle' | 'success';
export const successTimeout = isTestEnv() ? 250 : 2000;

const formatRebateRedeemLink = (url: string): string => {
  if (!window) return url;

  const { protocol } = window.location;

  return url.includes(protocol) ? url : `${protocol}//${url}`;
};

const CopyPasteBox: FC<CopyPasteBoxProps> = ({
  className = null,
  text,
  url = '',
  onCopy,
  onError,
  showLinkMode = false,
  isLoading = false,
  successText = 'Copied',
  onLinkClick,
}) => {
  const [status, setStatus] = useState<CopyPasteBoxState>('idle');
  const copyToClipboard = async (): Promise<void> => {
    if (isLoading || !text) return;
    try {
      await ClipboardService.writeText(text);
      setStatus('success');
      if (onCopy) onCopy();
      await wait(successTimeout);
      setStatus('idle');
    } catch (err) {
      if (onError) onError(err);
    }
  };

  const baseClassName = 'copy-paste-box';

  if (showLinkMode) {
    return (
      <div className={`${getClassNames(baseClassName, styles)} ${className || ''}`}>
        <ReactPlaceholder style={{ marginTop: 0 }} type="textRow" ready={!isLoading} showLoadingAnimation>
          <a target="_blank" rel="noreferrer" href={formatRebateRedeemLink(url)} onClick={onLinkClick}>
            {text}
          </a>
        </ReactPlaceholder>
      </div>
    );
  }

  return (
    <div
      className={`${getClassNames(baseClassName, styles)} ${className || ''}`}
      role="button"
      tabIndex={0}
      onClick={(event): void => {
        event.stopPropagation();
        copyToClipboard();
      }}
      data-testid={DATA_TEST_COPY_PASTE_BOX}
    >
      {status === 'success' && (
        <div
          className={getClassNames('copy-paste-box__success', styles)}
          data-testid={DATA_TEST_COPY_PASTE_BOX_SUCCESS}
        >
          <span>{successText}</span>
          <FontAwesomeIcon className={getClassNames('copy-paste-box__success-icon', styles)} icon={faCheck} />
        </div>
      )}
      <ReactPlaceholder style={{ marginTop: 0 }} type="textRow" ready={!isLoading} showLoadingAnimation>
        {text} <FontAwesomeIcon className={getClassNames('copy-paste-box__copy-icon', styles)} icon={faCopy} />
      </ReactPlaceholder>
    </div>
  );
};

export default CopyPasteBox;
