import { hidePopover } from '../popovers/popovers';

const popover = `div.product-item .match-score [rel="popover-clickable"]`;

function addOrRemoveClass(element, cls, show) {
  if (element) {
    if (show) element.classList.add(cls);
    else element.classList.remove(cls);
  }
}

function getMatchScore(
  productID,
  productClassname,
  mattressSize,
  matchScoreElement,
  loaderElement,
  retailerID,
  storeID,
) {
  if (!productID || !productClassname || !mattressSize) {
    return;
  }

  const baseURL = `/api/v2/match-data/?product=${productID}&classname=${productClassname}&size=${mattressSize}&is_listed=1`;
  let url = '';

  if (retailerID) url = `${baseURL}&retailer=${retailerID}`;
  else if (storeID) url = `${baseURL}&store=${storeID}`;
  else url = baseURL;

  addOrRemoveClass(matchScoreElement, 'loading', true);
  addOrRemoveClass(loaderElement, 'spinner-border', true);

  // eslint-disable-next-line
  return new Promise((resolve, reject) =>
    // eslint-disable-next-line
    $.get(url)
      .done((result) => {
        addOrRemoveClass(matchScoreElement, 'loading', false);
        addOrRemoveClass(matchScoreElement, 'empty', false);
        addOrRemoveClass(matchScoreElement, 'has-click', false);
        addOrRemoveClass(loaderElement, 'spinner-border', false);
        matchScoreElement.removeAttribute('onclick');

        // eslint-disable-next-line
        matchScoreElement.innerHTML = result.content;

        resolve(result);
      })
      .fail(() => {
        addOrRemoveClass(matchScoreElement, 'loading', false);
        addOrRemoveClass(loaderElement, 'spinner-border', false);

        reject();
      }),
  );
}

window.getAllMatchScoreData = () => {
  const promises = [];

  document.querySelectorAll('div.product-item .match-score').forEach((element) => {
    const productID = element.getAttribute('data-product-id');
    const productSlug = element.getAttribute('data-product-slug');
    const productClassname = element.getAttribute('data-product-classname');
    const mattressSize = element.getAttribute('data-mattress-size');
    const storeID = element.getAttribute('data-store-id');
    const retailerID = element.getAttribute('data-retailer-id');
    const matchScore = document.getElementById(`${productSlug}-match-score`);
    const loader = document.getElementById(`${productSlug}-match-score-loader`);

    promises.push(getMatchScore(productID, productClassname, mattressSize, matchScore, loader, retailerID, storeID));
  });

  Promise.all(promises).then(() => {
    window.initializePopovers();
    window.initializeMatchDataCollapsible(null, '.match-data.modal');

    $('.match-data-modal').on('shown.bs.modal', () => {
      window.initializeMatchDataTitlesAdjustments();
    });

    $(document).on('click', (e) => {
      $(popover).each(() => {
        hidePopover(e);
      });
    });
  });
};

window.handleGetMatchScore = (productSlug, retailerID = null, storeID = null) => {
  const matchScore = document.getElementById(`${productSlug}-match-score`);
  const loader = document.getElementById(`${productSlug}-match-score-loader`);
  const productID = matchScore.getAttribute('data-product-id');
  const productClassname = matchScore.getAttribute('data-product-classname');
  const mattressSize = matchScore.getAttribute('data-mattress-size');

  getMatchScore(productID, productClassname, mattressSize, matchScore, loader, retailerID, storeID);
};
