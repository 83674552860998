import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../base-modal';
import { ldpMetricsAction } from '../../../utils';
import {
  LDP_METRICS_MANUFACTURER_CLASSNAME,
  LDP_METRICS_OTHER_PAGE,
  LDP_METRICS_PROFILE_PAGE,
  LDP_METRICS_RETAILER_CLASSNAME,
  LDP_METRICS_STORE_CLASSNAME,
  LDP_METRICS_STORE_LOCATOR_PAGE,
  LDP_METRICS_TRY_THIS_BED_PAGE,
  LDP_METRICS_WEBSITE_ACTION,
} from '../../../constants';
import CashbackPromoButton from '../buttons/cashback';

class BasePromoModal extends React.Component {
  constructor(props) {
    super(props);

    this.renderModalBody = this.renderModalBody.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { storeSlug, locationPage, objectClassname, isLDP } = this.props;

    if (storeSlug && locationPage && objectClassname && isLDP) {
      ldpMetricsAction(locationPage, LDP_METRICS_WEBSITE_ACTION, storeSlug, objectClassname);
    }
  }

  renderModalBody() {
    const { body, promotionID, storeSlug, retailerSlug, storeName, externalURL, externalDomain, outboundURL } =
      this.props;

    return (
      <>
        {body}
        {promotionID && (storeSlug || retailerSlug) && (
          <div className="btn-container">
            <CashbackPromoButton
              storeSlug={storeSlug}
              retailerSlug={retailerSlug}
              storeName={storeName}
              promotionID={promotionID}
              longText
            />
          </div>
        )}
        {externalURL && outboundURL && externalDomain && (
          <div className="btn-container">
            <a
              className="outbound-btn"
              href={outboundURL}
              target="_blank"
              rel="nofollow noreferrer"
              onClick={this.handleClick}
            >
              <b>Shop this Sale</b>
              <br />
              <span>on {externalDomain}</span>
            </a>
          </div>
        )}
      </>
    );
  }

  render() {
    const { className, title, subtitle, onHide } = this.props;

    return (
      <Modal
        className={`promo-modal ${className}`}
        bodyTitle={title}
        subtitle={subtitle}
        body={this.renderModalBody()}
        onHide={onHide}
      />
    );
  }
}

BasePromoModal.defaultProps = {
  className: null,
  body: null,
  locationPage: undefined,
  objectClassname: undefined,
  isLDP: false,
};

BasePromoModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.element,
  storeSlug: PropTypes.string.isRequired,
  retailerSlug: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  promotionID: PropTypes.number,
  externalURL: PropTypes.string,
  externalDomain: PropTypes.string,
  outboundURL: PropTypes.string,
  locationPage: PropTypes.oneOf([
    LDP_METRICS_STORE_LOCATOR_PAGE,
    LDP_METRICS_TRY_THIS_BED_PAGE,
    LDP_METRICS_OTHER_PAGE,
    LDP_METRICS_PROFILE_PAGE,
  ]),
  objectClassname: PropTypes.oneOf([
    LDP_METRICS_STORE_CLASSNAME,
    LDP_METRICS_RETAILER_CLASSNAME,
    LDP_METRICS_MANUFACTURER_CLASSNAME,
  ]),
  isLDP: PropTypes.bool,
  onHide: PropTypes.func,
};

export default BasePromoModal;
