
export function setFavTooltips(elems) {
  $(elems).each(function() {
    var model = $(this).data("display");
    if (model === "mattresscollection") {
      model = "collection";
    }
    if ($(this).data("action") === "add") {
      $(this).attr("data-original-title", "Save this " + model + " to my list");
      $(this).find(".favorite-btn").attr("data-original-title", "");
    } else {
      $(this).attr("data-original-title", "");
      $(this)
        .find(".favorite-btn")
        .attr("data-original-title", "Remove this " + model + " from my list");
    }

    if (!window.is_mobile) {
      $(this).tooltip();
    }
  });
}

var switchFavorite = function(elem, activate, titles, clickover) {
  titles = titles || {};
  titles[0] = titles[0] || $(elem).data("addText");
  titles[1] = titles[1] || $(elem).data("removeText");
  const t = $(elem);
  let cls, rmcls, action, text;
  if (activate) {
    cls = "favorite";
    rmcls = "favorited";
    action = "add";
    text = titles[0];
  } else {
    cls = "favorited";
    rmcls = "favorite";
    action = "remove";
    text = titles[1];
  }
  if (text) t.find(".favorite-text").text(text);
  t
    .removeClass(rmcls)
    .addClass(cls)
    .attr("data-action", action)
    .data("action", action);
  setFavTooltips(t);
  // refresh tip html if it's a button inside a clickover
};


export function do_favorite_ajaxy(elem, callback) {
  const model = elem.data("model"), slug = elem.data("slug");
  const wrap = function (resp) {
    // counter-intuitive, but if we get `action: 'remove'` in the response,
    // the object was successfully saved.
    if (resp.action === "remove") {
      // trackObjectSave(model, slug);
    }
    $(".navbar .list-badge").text(resp.favorites_count);
    callback(resp);
  };

  $.ajax({
    url: "/favorite/" + elem.data("action") + "/" + model + "/" + slug + "/",
    context: $(this),
    type: "POST",
    dataType: "json",
    success: wrap
  });
}

export function favorite_btn_ajaxy(elem, clickover, titles) {
  var t = $(elem);
  do_favorite_ajaxy(t, function(resp) {
    // make sure page is up-to-date
    switchFavorite(elem, resp.action === "add", titles, clickover);
  });
}

function setupFavButtons(buttons, clickover) {
  buttons.mouseup(function() {
    favorite_btn_ajaxy(this, clickover, null);
  });
  setFavTooltips(buttons);
}


function updateFavState(buttons) {
  let query = [];
  buttons.each(function() {
    var model = $(this).data("model"), slug = $(this).data("slug");
    query[query.length] = model + "," + slug;
  });
  $.ajax({
    url: "/favorite/check-many/",
    type: "get",
    data: { obj: query },
    success: function(data) {
      for (i = 0; i < data.length; i++) {
        var model = data[i][0], slug = data[i][1], active = data[i][2];
        var btn = buttons.filter(
          "[data-model=" + model + "][data-slug=" + slug + "]"
        );
        switchFavorite(btn, !active);
      }
      buttons.css("display", "inline-block");
    }
  });
}

export function setupPreload(fetchCallback, blockSel) {
  var onClickover = function() {
    // hook up cool things on ajax-loaded html
    var clickover = this,
      obj = clickover.$element.parents(blockSel),
      buttons = clickover.tip().find(".favorite-btn");
    fetchCallback(obj);
    updateFavState(buttons);
    setupFavButtons(buttons, clickover);
  };
  // hook the onShown event
  $("[rel=clickover]").each(function() {
    $(this).data("clickover").options.onShown = onClickover;
  });
  var results = $(blockSel);
  // this fetches result data in the background
  var preloadClickovers = function() {
    var defaultTimeout = 800;
    var pos = 0;
    var preload = function() {
      if (pos >= results.length) return;
      var timeout = 0;
      if (fetchCallback(results.eq(pos))) timeout = defaultTimeout;
      pos++;
      setTimeout(preload, timeout);
    };
    // start chain
    preload();
  };
  preloadClickovers();
}

const price_ranges = [
  "Low ($0-$500)",
  "Lower-Mid ($500-$1,000)",
  "Upper-Mid ($1,000-$1,500)",
  "High-End ($1,500-$2,500)",
  "Luxury ($2,500-$4,000)",
  "Ultra-Luxury (>$4,000)"
];

export function setupPriceMeterTooltips() {
  $.each(price_ranges, function(i, pr) {
    $(".price" + (i + 1)).tooltip({ title: pr });
  });
}

//todo: not used anywhere, delete?
function insert_other_listener(other, text) {
  // enables and disabled text control by checking a checkbox
  $(function() {
    var chk = $(other);
    var txt = $(text);
    chk.click(function() {
      if (chk.attr("checked")) {
        txt.removeAttr("disabled");
        txt.focus();
      } else {
        txt.attr("disabled", "disabled");
      }
    });
    if (txt.val()) {
      chk.attr("checked", true);
    } else {
      txt.attr("disabled", "disabled");
    }
  });
}

//todo: not used anywhere, delete?
//sends ajax POST request to favorite or unfavorite given object
function favorite_ajaxy() {
  var t = $(this);
  do_favorite_ajaxy(t, function(resp) {
    t.html(resp.text).attr("class", resp["class"]).data("action", resp.action);
    $("#favorites-btn").replaceWith(resp.box_content);
    // initialize login button for newly replaced favorites box
    $("#favorites-btn .login-btn").colorbox(loginbox_options);
  });
}

export function updateClickover(clickoverElem, html) {
  var clickover = $(clickoverElem).data("clickover");
  var content = $(clickover.tip()).find("div.popover-content");
  content.html(html);
  clickover.options.content = "";
  clickover.options.html = true;
  $(clickover.$element).attr("data-content", html);
}
