import React, { FC } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CTAClickCategory, CTAClickLevel, PageRegion, PromoType } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import PromoTypeChip from '../../atoms/PromoTypeChip';
import LinkButton from '../../atoms/LinkButton';
import PromoChip from '../../atoms/PromoChip';
import useProductDiscountModal from '../../../hooks/useProductDiscountModal';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import {
  formatProductToDeal,
  getRebatePromoPayout,
  getSeasonDiscountText,
  openProductDiscountOutboundUrl,
} from '../../../utils/promos';
import { MattressModel } from '../../../types/mattresses';
import { PromotionType } from '../../../types/promotion';
import { User } from '../../../types/user';
import { Deal } from '../../../types/deal';
import { Brand } from '../../../types/brands';
import PosthogService from '../../../utils/posthogService';
import DiscountBox from '../../atoms/DiscountBox';
import {
  PostHogExperimentEvents,
  PostHogFeatureFlags,
  PostHogFeatureFlagVariantKeys,
} from '../../../constants/feature-flags';

/*
  IMPORTANT!!!!! Remember to also check backend/app/react-components/ProductDiscountBox.jsx
  if you change props on this component.
*/

export const PRODUCT_DISCOUNT_BOX_ID = 'product-discount-box';

export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';
export const SMALL = 'small';
export const XS_SMALL = 'xsSmall';

export type ProductDiscountBoxProps = {
  userData: User | null;
  pageRegion: PageRegion;
  product?: MattressModel;
  layout?: typeof VERTICAL | typeof HORIZONTAL | typeof SMALL | typeof XS_SMALL;
  posthogTesting?: boolean;
  posthogSidebarWidthVariant?: boolean;
  onGetDeal(deal: Deal): void;
};

const ProductDiscountBox: FC<ProductDiscountBoxProps> = ({
  userData,
  pageRegion,
  product,
  layout = 'vertical',
  posthogTesting = false,
  posthogSidebarWidthVariant = false,
  onGetDeal,
}) => {
  useProductDiscountModal(
    userData,
    product,
    (s, promo, data: Brand | MattressModel): void => {
      onGetDeal(formatProductToDeal(data));
    },
    { pageRegion, promoType: PromoType.ONLINE },
  );
  const posthog = new PosthogService(userData as User);
  const onlinePromotion = product?.extra_data?.promotion || undefined;
  const rebatePromotion = product?.extra_data?.cashback_promotion || undefined;
  const hasPromotion = onlinePromotion || rebatePromotion;
  const isOnlyOnlinePromotion = onlinePromotion && !rebatePromotion;
  const onlyOnePromo = (!onlinePromotion && rebatePromotion) || (onlinePromotion && !rebatePromotion);
  const baseCTAData = {
    level: CTAClickLevel.PRIMARY,
    product: product ? getProductEventDataWithMattressHierarchy(product) : EMPTY_EVENT_PRODUCT,
    pageRegion,
  };
  const ctaData = {
    ...baseCTAData,
    category: isOnlyOnlinePromotion ? CTAClickCategory.ONLINE_PROMOTION : CTAClickCategory.CASHBACK_PROMOTION,
    url: isOnlyOnlinePromotion ? onlinePromotion.outbound_url : rebatePromotion?.outbound_url,
  };
  const isPosthogTestFlag =
    posthog.getFeatureFlag(PostHogFeatureFlags.PROMO_BOX_DESIGN) === PostHogFeatureFlagVariantKeys.TEST;
  const isSmallLayout = layout === SMALL || layout === XS_SMALL;
  const dottedBorder = isPosthogTestFlag && posthogTesting && !isSmallLayout;

  const handleGetDeal = (item: MattressModel): void => {
    const { classname, slug } = item;
    const id = onlinePromotion ? onlinePromotion.id : rebatePromotion?.id;
    const outboundURL = onlinePromotion ? onlinePromotion.outbound_url : rebatePromotion?.outbound_url;
    const cashbackPromoID = rebatePromotion?.id;
    const isOnlyCashback = rebatePromotion && !onlinePromotion;

    if (id && classname && slug)
      openProductDiscountOutboundUrl(id, classname, slug, outboundURL, cashbackPromoID, isOnlyCashback);
  };

  const getPromoDiscountTitle = (): JSX.Element => {
    if (!onlinePromotion && layout === XS_SMALL) return <b>{getSeasonDiscountText(new Date()).toUpperCase()}</b>;

    return (
      <>
        <b>{onlinePromotion?.title_short || ''}</b>
        {layout !== XS_SMALL && onlinePromotion && rebatePromotion ? (
          <span>
            <br /> plus
          </span>
        ) : null}
        {layout !== XS_SMALL && rebatePromotion ? <b>{rebatePromotion.title_short}</b> : null}
      </>
    );
  };

  return (
    <DiscountBox
      className={cls('product-discount-box', layout, {
        empty: !hasPromotion,
        'dotted-border': dottedBorder,
        'only-one-promo': onlyOnePromo,
        'sidebar-width-variant': posthogSidebarWidthVariant,
      })}
      dottedBorder={dottedBorder}
      smallIcon={isSmallLayout}
    >
      {hasPromotion ? (
        <LinkButton
          className="product-discount-box__link-btn"
          ariaLabel="Get Deal"
          rel="nofollow"
          to={isOnlyOnlinePromotion ? onlinePromotion.outbound_url : undefined}
          ctaData={ctaData}
          useRouter={false}
          onClick={(): void => {
            if (product) handleGetDeal(product);

            if (posthogTesting) {
              posthog.capture(PostHogExperimentEvents.PRODUCT_DISCOUNT_BOX_CLICKED);
            }
          }}
        >
          {layout === HORIZONTAL && product ? (
            <div className="product-discount-box__logo">
              <img src={product?.logo} alt={`${product.name} logo`} />
              {rebatePromotion?.payout_type ? (
                <PromoChip
                  label={getRebatePromoPayout(
                    rebatePromotion?.percentage_payout || 0,
                    rebatePromotion?.fixed_payout_amount || 0,
                    rebatePromotion?.payout_type,
                  )}
                />
              ) : null}
            </div>
          ) : null}

          {layout !== XS_SMALL ? (
            <div className="product-discount-box__chips">
              {onlinePromotion && <PromoTypeChip promotionType={PromotionType.ONLINE} />}
              {onlinePromotion && rebatePromotion && <FontAwesomeIcon className="plus-icon" icon={faPlus} />}
              {rebatePromotion && <PromoTypeChip promotionType={PromotionType.REBATE} />}
            </div>
          ) : null}

          <div className="product-discount-box__text-container">
            <div className="product-discount-title">{getPromoDiscountTitle()}</div>
            {layout !== XS_SMALL && (isOnlyOnlinePromotion || (!onlinePromotion && rebatePromotion)) && (
              <div className="product-discount-subtitle">
                {onlinePromotion ? onlinePromotion?.subtitle : rebatePromotion?.subtitle}
              </div>
            )}
          </div>
          {layout === XS_SMALL && rebatePromotion ? (
            <div className="product-discount-cashback-title">
              <b>+{rebatePromotion.title_short.replace('CASH ', '')}</b>
            </div>
          ) : null}
          {layout === VERTICAL && !dottedBorder ? <div className="discount-box__get-deal">Get Deal</div> : null}

          {dottedBorder && (
            <div className="btn-promotion">
              <div className="btn-promotion-text">Get Deal</div>
              <div className="btn-promotion-code" />
              <span className="btn-promotion-layer" />
              <span className="btn-promotion-image" />
            </div>
          )}
        </LinkButton>
      ) : (
        <>
          <div className="empty-discount-text">
            No discounts <br /> {isSmallLayout ? null : 'currently available'}
          </div>
          {isSmallLayout ? (
            <div className="other-deals-text">
              <a href={product?.brand_outbound_url} target="_blank" rel="noreferrer">
                Check for other deals
              </a>
            </div>
          ) : (
            <div className="other-deals-text">
              Check for other deals for this brand{' '}
              <a href={product?.brand_outbound_url} target="_blank" rel="noreferrer">
                here
              </a>
              .
            </div>
          )}
        </>
      )}
    </DiscountBox>
  );
};

export default ProductDiscountBox;
