import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import BasePromoModal from './base-promo-modal';
import { BRAND_PROMO, RETAILER_PROMO } from '../constants';
import {
  LDP_METRICS_STORE_LOCATOR_PAGE,
  LDP_METRICS_TRY_THIS_BED_PAGE,
  LDP_METRICS_OTHER_PAGE,
  LDP_METRICS_PROFILE_PAGE,
  LDP_METRICS_STORE_CLASSNAME,
  LDP_METRICS_RETAILER_CLASSNAME,
  LDP_METRICS_MANUFACTURER_CLASSNAME,
} from '../../../constants';
import { handleComponentUnmount } from '../../utils';

const MODAL_ID = 'general-promo-modal';

function GeneralPromoModal({
  type,
  storeSlug,
  storeName,
  retailerSlug,
  retailerName,
  productName,
  title,
  description,
  promotionID,
  externalURL,
  externalDomain,
  outboundURL,
  locationPage,
  objectClassname,
  isLDP,
  dismiss,
  onHide,
  unmount,
}) {
  const handleCloseModal = () => {
    if (dismiss) {
      const storeModalID = `${storeSlug}-store-qv`;

      // Check if modal exist
      const element = document.getElementById(storeModalID);

      // Show modal
      if (storeSlug && element) $(`#${storeModalID}`).modal('show');
    } else if (onHide) onHide();

    // Unmount the promo modal component.
    unmount();
  };

  return (
    <BasePromoModal
      className="general-promo-modal"
      title={title}
      subtitle={type === BRAND_PROMO && retailerName ? `Save on ${productName} at ${retailerName}` : retailerName}
      body={<div className="description" dangerouslySetInnerHTML={{ __html: description || '' }} />}
      storeSlug={storeSlug}
      storeName={storeName}
      retailerSlug={retailerSlug}
      promotionID={promotionID}
      externalURL={externalURL}
      externalDomain={externalDomain}
      outboundURL={outboundURL}
      locationPage={locationPage}
      objectClassname={objectClassname}
      isLDP={isLDP}
      onHide={handleCloseModal}
    />
  );
}

GeneralPromoModal.defaultProps = {
  dismiss: true,
};

GeneralPromoModal.propTypes = {
  type: PropTypes.oneOf([BRAND_PROMO, RETAILER_PROMO]).isRequired,
  storeSlug: PropTypes.string,
  storeName: PropTypes.string.isRequired,
  retailerName: PropTypes.string.isRequired,
  retailerSlug: PropTypes.string,
  productName: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  externalURL: PropTypes.string,
  externalDomain: PropTypes.string,
  outboundURL: PropTypes.string,
  locationPage: PropTypes.oneOf([
    LDP_METRICS_STORE_LOCATOR_PAGE,
    LDP_METRICS_TRY_THIS_BED_PAGE,
    LDP_METRICS_OTHER_PAGE,
    LDP_METRICS_PROFILE_PAGE,
  ]),
  objectClassname: PropTypes.oneOf([
    LDP_METRICS_STORE_CLASSNAME,
    LDP_METRICS_RETAILER_CLASSNAME,
    LDP_METRICS_MANUFACTURER_CLASSNAME,
  ]),
  isLDP: PropTypes.bool,
  dismiss: PropTypes.bool,
  onHide: PropTypes.func,
  unmount: PropTypes.func,
};

export function renderModal(data) {
  const element = document.getElementById(MODAL_ID);

  if (data) {
    const root = createRoot(element);

    root.render(
      <GeneralPromoModal
        type={data.type || ''}
        storeSlug={data.storeSlug || ''}
        storeName={data.storeName || ''}
        retailerSlug={data.retailerSlug || ''}
        retailerName={data.retailerName || ''}
        productName={data.productName || ''}
        title={data.title || ''}
        description={data.description || ''}
        promotionID={data.promotionID ? parseInt(data.promotionID, 10) : null}
        externalURL={data.externalURL || ''}
        externalDomain={data.externalDomain}
        outboundURL={data.outboundURL || ''}
        locationPage={data.locationPage}
        objectClassname={data.objectClassname}
        isLDP={data.isLDP}
        dismiss={data.dismiss}
        onHide={data.onHide}
        unmount={() => {
          handleComponentUnmount(MODAL_ID, root);
        }}
      />,
    );
  }
}

export default GeneralPromoModal;
