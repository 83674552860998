import { create } from 'zustand';
import { CompareItem, CompareType } from '../../../types/mattress-comparison';

export interface CompareWidgetStore {
  compareItems: CompareItem[];
  setCompareItems: (items: CompareItem[]) => void;
  selectItem: (item: CompareItem) => void;
  removeItem: (slug: CompareItem['slug']) => void;
  compareType: null | CompareType;
  setCompareType: (type: CompareType) => void;
}

const useCompareWidgetState = create<CompareWidgetStore>((set, get) => ({
  compareItems: [],
  compareType: null,
  setCompareItems: (items): void => {
    set({
      compareItems: items,
    });
  },
  selectItem: (item): void => {
    const currentItems = get().compareItems;
    let newItems: CompareItem[] = [];

    const isAlreadySelected = !!currentItems.find((selectedItem) => selectedItem.slug === item.slug);

    if (!isAlreadySelected) {
      newItems = currentItems.concat(item);
    }

    set({ compareItems: newItems });
  },
  removeItem: (slug): void => {
    const newItems = get().compareItems.filter((item) => item.slug !== slug);

    set({
      compareItems: newItems,
    });
  },
  setCompareType: (type): void => {
    set({ compareType: type });
  },
}));

export default useCompareWidgetState;
