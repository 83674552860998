import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

const PROMOS_TOASTER_ID = 'promos-toaster';

const LazySUPromosToaster = lazy(
  () => import(/* webpackChunkName: "promos-toaster" */ 'shared-ui/src/components/molecules/PromosToaster'),
);

function PromosToaster({ onGetDeal }) {
  return (
    <Suspense fallback={null}>
      <LazySUPromosToaster onGetDeal={onGetDeal} userData={window.userData} />
    </Suspense>
  );
}

export function renderPromosToaster(onGetDeal, id = PROMOS_TOASTER_ID) {
  const element = document.getElementById(id);

  if (element) {
    const root = createRoot(element);
    root.render(<PromosToaster onGetDeal={onGetDeal} />);
  }
}

export default PromosToaster;
