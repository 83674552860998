var getGlossaryTerm = function(elem) {
    // If the term isn't set in the glossary-term data attribute,
    // use the element's text. This is done to simplify the UI. :-)
    var termData = elem.data('glossary-term');
    var term = (typeof termData !== 'undefined' && termData) ?
        termData : $.trim(elem.text().replace(/(\r\n|\n|\r)+/gm," "));
    return term;
};

$(document).on('click', '.popover a', function(e) {
    $(this).attr('target', '_blank');
});

$(document).ready(function() {
    var $glosslinks = $('.glosslink');
    var terms = [];
    // Get all the terms on the page
    $glosslinks.each(function(idx, link) {
        var term = getGlossaryTerm($(link));
        if ($.inArray(term, terms) < 0) {
            terms.push(term);
        }
    });

    $glosslinks.filter('[href=\\#]').on('click', function(e) {
        e.preventDefault();
    });

    if (terms.length === 0) return;

    // Build the query string
    var qs = 'term=' + terms.join('&term=');

    // Fetch the glossary and setup the popovers
    $.getJSON('/mattress-glossary/define/', qs, function(data) {
        $glosslinks.each(function() {
            var $t = $(this),
                term = getGlossaryTerm($t),
                definition = (typeof data[term] === 'undefined') ? ['', ''] : data[term];
            $t.popover({
                html: true,
                trigger: 'hover',
                title: function() {
                    return definition[0];
                },
                content: function() {
                    return definition[1];
                }
            });
        });
    });

});
