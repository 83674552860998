/* eslint-disable no-param-reassign */
import { useState, useEffect, MutableRefObject } from 'react';

interface UseCollapseOptions {
  initialCollapse: boolean;
}

interface UseCollapseReturn {
  isCollapsed: boolean;
  isCollapsing: boolean;
  toggleCollapse: (state?: boolean) => void;
  recalculateHeight: () => void;
}

const defaultOptions: UseCollapseOptions = {
  initialCollapse: false,
};

const IS_COLLAPSING_CLASS = 'is-collapsing';
const COLLAPSABLE_ELEMENT_CLASS = 'collapsable-element';
const COLLAPSED_ELEMENT_CLASS = 'collapsed-element';

const useCollapse = <T extends HTMLElement>(
  elementToCollapseRef: MutableRefObject<T | null | undefined>,
  { initialCollapse } = defaultOptions,
): UseCollapseReturn => {
  const [isCollapsing, setIsCollapsing] = useState<boolean>(false);
  const [isCollapsed, toggleCollapse] = useState<boolean>(initialCollapse);
  const [elementInitialHeight, setElementInitialHeight] = useState<number | null>(null);

  const collapseElement = (state = !isCollapsed, height: string | undefined = undefined): void => {
    toggleCollapse(state);
    setIsCollapsing(true);

    if (elementToCollapseRef.current) {
      elementToCollapseRef.current.classList.add(IS_COLLAPSING_CLASS);

      if (state === true) {
        elementToCollapseRef.current.style.height = '0';
      }

      if (state === false) {
        elementToCollapseRef.current.style.height = `${height || elementInitialHeight}px`;
        elementToCollapseRef.current.classList.remove(COLLAPSED_ELEMENT_CLASS);
      }
    }
  };

  const recalculateHeight = (): void => {
    if (elementToCollapseRef.current) {
      elementToCollapseRef.current.style.height = 'auto';

      setElementInitialHeight(elementToCollapseRef.current.clientHeight);
      elementToCollapseRef.current.style.height = `${elementToCollapseRef.current.clientHeight}px`;
      collapseElement(isCollapsed, `${elementToCollapseRef.current.clientHeight}px`);
    }
  };

  useEffect(() => {
    let elementTransitionEventListener: any;

    const element = elementToCollapseRef.current;

    if (element && element.clientHeight && !elementInitialHeight) {
      setElementInitialHeight(element.clientHeight);

      // We need to set an explicit height in order to animate the height transition.
      element.style.height = isCollapsed ? '0px' : `${element.clientHeight}px`;

      elementToCollapseRef.current?.classList.add(COLLAPSABLE_ELEMENT_CLASS);

      elementTransitionEventListener = element.addEventListener('transitionend', () => {
        setIsCollapsing(false);
        if (element) {
          element.classList.remove(IS_COLLAPSING_CLASS);
        }
      });
    }

    return (): void => {
      window.removeEventListener('transitionend', elementTransitionEventListener);
    };
  }, [elementToCollapseRef.current]);

  return {
    isCollapsed,
    isCollapsing,
    toggleCollapse: collapseElement,
    recalculateHeight,
  };
};

export default useCollapse;
