export function parseLocalDate(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
}

export const getWeekDayByItsIndex = (weekDayIndex: number, abbreviated = false): string => {
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const abbreviatedWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return abbreviated ? abbreviatedWeekDays[weekDayIndex] : weekDays[weekDayIndex];
};
