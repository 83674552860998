import { useEffect, useState } from 'react';
import { captureException } from '@sentry/browser';
import fetchService from '../../utils/fetchService';
import { BrandDeal, DealItem } from '../../types/deal';
import { MattressModel } from '../../types/mattresses';
import { Brand } from '../../types/brands';
import { getBrandFromDeal } from '../../utils/promos';

interface PromoProductResponse {
  brand?: BrandDeal;
  deals?: DealItem[];
  product?: MattressModel | Brand | any;
}

interface UseFetchPromoProductResult extends PromoProductResponse {
  hasRequestFinished: boolean;
}

const useFetchPromoProduct = (productClassname: string, productSlug: string): UseFetchPromoProductResult => {
  const [data, setData] = useState<PromoProductResponse>({
    brand: undefined,
    deals: [],
    product: undefined,
  });
  const [hasRequestFinished, setHasRequestFinished] = useState(false);

  useEffect(() => {
    fetchService
      .get<PromoProductResponse>(`/${productClassname}/${productSlug}/promos/`)
      .then((response) => {
        if (response && response.brand) {
          if (response.deals?.length) {
            setData({
              ...response,
              product: getBrandFromDeal({
                brand: response.brand,
                deals: response.deals,
              }),
            });
          } else {
            setData({ ...response, product: response.brand });
          }
        }

        setHasRequestFinished(true);
      })
      .catch((err) => {
        setHasRequestFinished(true);

        const msg = 'Error fetching product promos';
        console.error(msg, err);
        captureException(new Error(`${msg} -> ${err}`));
      });
  }, []);

  return {
    ...data,
    hasRequestFinished,
  };
};

export default useFetchPromoProduct;
