import React, { FC } from 'react';
import ProductDiscountBoxFoldIcon from '../Icons/ProductDiscountBoxFold';
import ProductDiscountBoxFoldIconSmall from '../Icons/ProductDiscountBoxFoldSmall';
import styles from './DiscountBox.module.scss';
import { getClassNames } from '../../../utils/styles';

export const DISCOUNT_BOX_ID = 'discount-box';

export type DiscountBoxProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  dottedBorder?: boolean;
  smallIcon?: boolean;
};

const DiscountBox: FC<DiscountBoxProps> = ({ className = '', children, dottedBorder = false, smallIcon = false }) => {
  return (
    <div data-testid={DISCOUNT_BOX_ID} className={`${getClassNames('discount-box', styles)} ${className}`}>
      {children}
      {!dottedBorder && (
        <>
          <div
            className={
              smallIcon ? getClassNames('fold-background-small', styles) : getClassNames('fold-background', styles)
            }
          />
          {smallIcon ? (
            <ProductDiscountBoxFoldIconSmall className={getClassNames('fold-icon-small', styles)} />
          ) : (
            <ProductDiscountBoxFoldIcon className={getClassNames('fold-icon', styles)} />
          )}
        </>
      )}
    </div>
  );
};

export default DiscountBox;
