import { Filter } from '../types/commons';
import {
  FILTER_AGE_KEY,
  FILTER_CONDITION_TAGS_KEY,
  FILTER_DATE_PURCHASED_RANGES_KEY,
  FILTER_RATING_KEY,
  FILTER_REVIEW_TYPE_KEY,
  FILTER_SEX_KEY,
  FILTER_SLEEP_POSITION_KEY,
  FILTER_TARGET_PERSON_KEY,
  FILTER_WEIGHT_KEY,
} from './filter';

export const MIN_REVIEWS_COUNT = 10;

export const RATINGS = 'ratings';
export const REVIEWS = 'reviews';
export const RATING = 'rating';
export const CONDITION_TAGS = 'condition_tags';
export const DONT_KNOW = "Don't know";
export const ORDER_BY_FIELD = 'order_by';
export const SEARCH_FIELD = 'q';
export const DATE_PURCHASED_RANGES = 'date_purchased_ranges';
export const DATE_PURCHASED = 'date_purchased';
export const SEPARATOR = '-';
export const BASE_SEARCH_FIELD = `${SEARCH_FIELD}${SEPARATOR}`;

export const SET_FILTERS = 'set_filters';
export const SET_REVIEWS = 'set_reviews';
export const SET_LOADING = 'set_loading';
export const RESET = 'reset';

export const MATTRESSES_KEY = 'mattresses';
export const MATTRESS_COLLECTION_KEY = 'mattress-collection';
export const MATTRESS_LINE_KEY = 'mattress-line';
export const MATTRESS_MODEL_KEY = 'mattress-model';

export const ENDPOINTS_KEYS = {
  [MATTRESSES_KEY]: 'manufacturer',
  [MATTRESS_COLLECTION_KEY]: 'collection',
  [MATTRESS_LINE_KEY]: 'line',
  [MATTRESS_MODEL_KEY]: 'model',
};

export const DYNAMIC_PARAMS = ['slug', 'type'];

export const REVIEWS_FILTERS_ID = 'mattresses-filters';

export const REVIEWS_FILTERS: Filter[] = [
  {
    key: FILTER_SEX_KEY,
    title: 'Sleeper Gender',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_SLEEP_POSITION_KEY,
    title: 'Sleep Position',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_WEIGHT_KEY,
    title: 'Sleeper Weight',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_AGE_KEY,
    title: 'Sleeper Age',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_CONDITION_TAGS_KEY,
    title: 'Sleep Afflictions',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_TARGET_PERSON_KEY,
    title: 'Mattress Use',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_RATING_KEY,
    title: 'Overall Rating',
    data: [],
    order: 'desc',
  },
  {
    key: FILTER_REVIEW_TYPE_KEY,
    title: 'Rating Type',
    data: [],
    order: 'asc',
  },
  {
    key: FILTER_DATE_PURCHASED_RANGES_KEY,
    title: 'Owned For',
    data: [],
    order: 'asc',
  },
];

export const MOST_RECENT = 'Most Recent';
export const MOST_RECENT_VALUE = '';

export const HIGHEST_RATED = 'Highest Rated';
export const HIGHEST_RATED_VALUE = '-rating';

export const LOWEST_RATED = 'Lowest Rated';
export const LOWEST_RATED_VALUE = 'rating';

export const MOST_HELPFUL = 'Most Helpful';
export const MOST_HELPFUL_VALUE = 'most_helpful';

export const FILTER_ORDER_BY_OPTIONS = [
  { name: MOST_RECENT, value: MOST_RECENT_VALUE },
  { name: HIGHEST_RATED, value: HIGHEST_RATED_VALUE },
  { name: LOWEST_RATED, value: LOWEST_RATED_VALUE },
  { name: MOST_HELPFUL, value: MOST_HELPFUL_VALUE },
];
