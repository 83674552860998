import React, { FC } from 'react';
import { format } from 'date-fns';
import { CTAClickCategory, CTAClickLevel, CTAClickSubcategory, PageRegion } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import DataLayerService from 'datalayer-service';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import RebatePromoExpand from './RebatePromo';
import GeneralPromoExpand from './GeneralPromo';
import { Brand } from '../../../types/brands';
import { MattressModel } from '../../../types/mattresses';
import { EditorialRatingProduct } from '../../../types/mattress-comparison';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import { RetailerDeal } from '../../../types/deal';

export type PromoExpandBoxProps = {
  promotion: NewPromotion;
  pageRegion: PageRegion;
  product?: Brand | MattressModel | EditorialRatingProduct;
  retailer?: RetailerDeal;
  isEditorialProduct?: boolean;
  isLoadingShortLinks?: boolean;
  redeemLink?: string;
  toggleable?: boolean;
  requiredPromoCode?: boolean;
};

export const DATA_TEST_PROMO_EXPAND_ID = 'promo-expand-box-id';

const getDateFromISO = (date: string): Date => {
  const splittedISODate = date.split('-');

  return new Date(
    parseInt(splittedISODate[0], 10),
    parseInt(splittedISODate[1], 10) - 1,
    parseInt(splittedISODate[2], 10),
  );
};

const PromoExpandBox: FC<PromoExpandBoxProps> = ({
  promotion,
  pageRegion,
  product,
  retailer,
  isEditorialProduct = false,
  redeemLink = '',
  isLoadingShortLinks = false,
  toggleable = true,
  requiredPromoCode = false,
}) => {
  const showPromoCode = !promotion.link_only || requiredPromoCode;
  const imageSrc = retailer ? retailer.logo : (promotion.retailer?.logo as string);
  const { title, subtitle, reward_description: rewardDescription } = promotion;
  const retailerName = retailer ? retailer.name : promotion.retailer.name;

  const endDate = promotion.ends ? format(getDateFromISO(promotion.ends as string), 'MMMM d') : undefined;
  const baseCTAData = {
    level: CTAClickLevel.SECONDARY,
    product: product ? getProductEventDataWithMattressHierarchy(product, isEditorialProduct) : EMPTY_EVENT_PRODUCT,
    pageRegion,
  };
  let content: JSX.Element;

  const handleExpandBoxToggle = (category: CTAClickCategory, open: boolean): void => {
    const dataLayerService = new DataLayerService(window);

    dataLayerService.CTAClickEvent({
      ...baseCTAData,
      category,
      subcategory: open ? CTAClickSubcategory.EXPAND_PROMO_BOX : CTAClickSubcategory.COLLAPSE_PROMO_BOX,
      url: promotion.outbound_url,
    });
  };

  switch (promotion.promotion_type) {
    case PromotionType.RETAILER:
      content = (
        <GeneralPromoExpand
          expandTitle={`Store discount from ${retailerName}`}
          title={title}
          subTitle={subtitle}
          imageSrc={imageSrc}
          showPromoCode={showPromoCode}
          endDate={endDate}
          rewardDescription={rewardDescription}
          code={promotion.code}
          toggleable={toggleable}
          requiredPromoCode={requiredPromoCode}
          onToggle={(open: boolean): void =>
            handleExpandBoxToggle(CTAClickCategory.EXPAND_RETAILER_PROMO_DISCOUNT, open)
          }
        />
      );
      break;
    case PromotionType.BRAND:
      content = (
        <GeneralPromoExpand
          expandTitle={`Store discount from ${retailerName}`}
          title={title}
          subTitle={subtitle}
          imageSrc={imageSrc}
          showPromoCode={showPromoCode}
          endDate={endDate}
          rewardDescription={rewardDescription}
          code={promotion.code}
          toggleable={toggleable}
          requiredPromoCode={requiredPromoCode}
          onToggle={(open: boolean): void =>
            handleExpandBoxToggle(CTAClickCategory.EXPAND_RETAILER_PROMO_DISCOUNT, open)
          }
        />
      );
      break;
    case PromotionType.ONLINE:
      content = (
        <GeneralPromoExpand
          isOnline
          expandTitle={`Online discount from ${retailerName}`}
          title={title}
          subTitle={subtitle}
          imageSrc={imageSrc}
          showPromoCode={showPromoCode}
          endDate={endDate}
          rewardDescription={rewardDescription}
          code={promotion.code}
          toggleable={toggleable}
          requiredPromoCode={requiredPromoCode}
          onToggle={(open: boolean): void => handleExpandBoxToggle(CTAClickCategory.EXPAND_ONLINE_PROMO_DISCOUNT, open)}
        />
      );
      break;
    default:
      content = (
        <RebatePromoExpand
          isRedeemLinkLoading={isLoadingShortLinks}
          redeemLink={redeemLink}
          title={title}
          subTitle={subtitle}
          retailerName={retailerName}
          promotionType={promotion.promotion_type}
          endDate={endDate}
          toggleable={toggleable}
          onToggle={(open: boolean): void =>
            handleExpandBoxToggle(CTAClickCategory.EXPAND_CASHBACK_PROMO_DISCOUNT, open)
          }
        />
      );
      break;
  }

  return (
    <div className="shared-ui__promo-expand-box" data-testid={DATA_TEST_PROMO_EXPAND_ID}>
      {content}
    </div>
  );
};

export default PromoExpandBox;
