export const LDP_METRICS_STORE_LOCATOR_PAGE = 'STORE_LOCATOR';
export const LDP_METRICS_TRY_THIS_BED_PAGE = 'TRY_THIS_BED';
export const LDP_METRICS_OTHER_PAGE = 'OTHER_STORES';
export const LDP_METRICS_PROFILE_PAGE = 'PROFILE_PAGE';
export const LDP_METRICS_PHONE_CALL_ACTION = 'PHONE_CALL';
export const LDP_METRICS_PHONE_HOVER_ACTION = 'PHONE_HOVER';
export const LDP_METRICS_SMS_OPEN_ACTION = 'SMS_OPEN';
export const LDP_METRICS_SMS_SEND_ACTION = 'SMS_SEND';
export const LDP_METRICS_WEBSITE_ACTION = 'WEBSITE';
export const LDP_METRICS_STORE_CLASSNAME = 'store';
export const LDP_METRICS_MANUFACTURER_CLASSNAME = 'manufacturer';
export const LDP_METRICS_RETAILER_CLASSNAME = 'retailer';
