/*
 * Note window.navigator.clipboard.writeText requires a secure origin — either HTTPS or localhost
 * For get working on our local development setup:
 *    In chrome://flags, enable Insecure origins treated as secure and add the origin http://nginx:8080
 */
function writeToClipboardWithFallback(text: string): Promise<void> {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  }
  // Fallback for browsers that don't support navigator.clipboard
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  } finally {
    document.body.removeChild(textArea);
  }
}

class ClipboardService {
  static writeText(text: string): Promise<void> {
    return writeToClipboardWithFallback(text);
  }
}

export default ClipboardService;
