import React, { useEffect, useState } from 'react';
import { SaveDealsSentStateType, URL_DEAL_PARAM } from '../../utils/saveDealsService';

export function getSuccessModalData(): SaveDealsSentStateType {
  const { href, search } = window.location;
  const dealHashIdx = href.indexOf(URL_DEAL_PARAM);
  const queryParams = new URLSearchParams(search);

  const result: SaveDealsSentStateType = {
    show: true,
    email: queryParams.get('methods')?.includes('email') ?? false,
    sms: queryParams.get('methods')?.includes('sms') ?? false,
    isMulti: queryParams.get('multi') === 'true',
  };

  if (dealHashIdx > -1) {
    window.history.pushState(null, '', href.substring(0, dealHashIdx - 1));
    return {
      ...result,
      show: true,
    };
  }

  return {
    ...result,
    show: false,
  };
}

export default function useSaveDealSuccessModal(): SaveDealsSentStateType {
  const [showSuccessSaveDealsModal, setShowSuccessSaveDealsModal] = useState<SaveDealsSentStateType>({
    show: false,
    email: false,
    sms: false,
    isMulti: false,
  });

  useEffect(() => {
    const data = getSuccessModalData();
    setShowSuccessSaveDealsModal(data);
  }, []);

  return showSuccessSaveDealsModal;
}
