/** *
 * Get the action data used for LDP metrics.
 *
 * @param htmlComponent: HTML component from where get the data.
 *
 * @returns {{location_page: string, action_type: string, slug: string, request_path: string, object_classname: string}}
 */
const getActionData = (htmlComponent) => {
  const requestPath = window.location.pathname + window.location.search;

  const objectClassname = htmlComponent.data('object-classname').trim();

  const locationPage = htmlComponent.data('location-page');
  const actionType = htmlComponent.data('action-type');
  const slug = (htmlComponent.data('slug') || htmlComponent.data('store-slug') || '').trim();

  return {
    location_page: locationPage, // jshint ignore:line
    action_type: actionType, // jshint ignore:line
    request_path: requestPath, // jshint ignore:line
    object_classname: objectClassname, // jshint ignore:line
    slug,
  };
};

/** *
 * Send data to backend LDP metrics.
 *
 * @param actionData: Action data to send to LDP metrics.
 */
const sendData = (actionData) => {
  const apiPath = '/api/v1/partners/ldp-metrics/action';

  const newActionData = {
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    ...actionData,
  };

  $.ajax({
    url: apiPath,
    type: 'POST',
    data: newActionData,
    success: (response) => {
      if (window.DEBUG) {
        // jshint ignore:line
        console.error('LDP Metrics 📈:', response);
      }
    },
  });
};

/* LDP Metric Actions */
// Call store actions will be done in the phone-button.jsx component.
const clickSelectors = [
  'a.store-website',
  'a.object-website',
  'a.store-get-directions-btn',
  'div.store-open-sms-btn',
  '.outbound-btn',
  'a.btn.price-btn.mt-3',
  'button.btn.quick-view',
];

export default function trackLDPMetrics() {
  $(clickSelectors.join(',')).on('click', (e) => {
    const htmlComponent = $(e.currentTarget);

    const objectClassname = htmlComponent.data('object-classname').trim();
    const actionData = getActionData(htmlComponent);

    if (objectClassname === 'store' || objectClassname === 'retailer') {
      sendData(actionData);
    }
  });
}
