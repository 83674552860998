import React, { useEffect, useState } from 'react';
import fetch from '../utils/fetchService';
import { NewPromotion, PromotionType } from '../types/promotion';

export const SHORT_LINKS_ENDPOINT = '/promos/short-links';

export type UsePromoShoppingShortUrlType = {
  isLoading: boolean;
  onlineShoppingLink?: string;
  rebateShoppingLink?: string;
  rebateRedeemLink?: string;
  redeemExpirationDate?: string;
  redeemInvalidationDate?: string;
};

type PayloadType = {
  id: string | number;
  type: PromotionType;
};

type ShortLink = {
  id: string;
  type: PromotionType;
  redeem_link?: string;
  shopping_link?: string;
  expiration_date?: string;
  invalidation_date?: string;
};

export default function usePromoModalShortLinks(
  rebatePromotion: NewPromotion | undefined,
  generalPromotion?: NewPromotion,
  doNotCallApi?: boolean,
): UsePromoShoppingShortUrlType {
  const [result, setResult] = useState<UsePromoShoppingShortUrlType>({
    isLoading: false,
  });

  useEffect(() => {
    if (!doNotCallApi && (generalPromotion || rebatePromotion)) {
      const body: PayloadType[] = [];

      if (rebatePromotion) body.push({ id: rebatePromotion.id, type: PromotionType.REBATE });
      if (generalPromotion) body.push({ id: generalPromotion.id, type: generalPromotion.promotion_type });

      setResult({ isLoading: true });
      fetch
        .post<ShortLink[]>(SHORT_LINKS_ENDPOINT, body)
        .then((shortLinks) => {
          const data: UsePromoShoppingShortUrlType = { isLoading: false };
          shortLinks?.forEach((shortLink) => {
            switch (shortLink.type) {
              case PromotionType.REBATE:
                data.rebateShoppingLink = shortLink.shopping_link;
                data.rebateRedeemLink = shortLink.redeem_link;
                data.redeemExpirationDate = shortLink.expiration_date;
                data.redeemInvalidationDate = shortLink.invalidation_date;
                break;
              default:
                data.onlineShoppingLink = shortLink.shopping_link;
            }
          });
          setResult({ ...data, isLoading: false });
        })
        .catch(() => {
          setResult({ isLoading: false });
        });
    } else {
      setResult({ isLoading: false });
    }
  }, [generalPromotion, doNotCallApi, rebatePromotion]);

  return { ...result };
}
