import React, { FC } from 'react';

interface RatingBarProps {
  rating: number;
}

const RatingBar: FC<RatingBarProps> = ({ rating = 0 }) => {
  return (
    <div className="bar-rating">
      <div className="bar-rating__bar">
        <div className="bar-rating__fill" style={{ width: `${rating}%` }} />
      </div>
    </div>
  );
};

export default RatingBar;
