import React, { FC } from 'react';
import ExpandBox, { OnToggleFnType } from '../../atoms/ExpandBox';
import PromoBox from '../../atoms/PromoBox';

export type GeneralPromoExpandProps = {
  expandTitle: string;
  title: string;
  subTitle: string;
  imageSrc: string;
  endDate?: string;
  code?: string;
  rewardDescription?: string;
  showPromoCode: boolean;
  toggleable?: boolean;
  isOnline?: boolean;
  requiredPromoCode?: boolean;
  onToggle: OnToggleFnType;
};

export const DATA_TEST_GENERAL_PROMO_EXPAND_CONTENT = 'general-promo-expand-content-test-id';
export const DATA_TEST_GENERAL_PROMO_EXPAND_HEADER = 'general-promo-expand-header-test-id';

const GeneralPromoExpand: FC<GeneralPromoExpandProps> = ({
  expandTitle,
  title,
  subTitle,
  endDate,
  imageSrc,
  showPromoCode,
  code,
  rewardDescription,
  toggleable = true,
  isOnline = false,
  requiredPromoCode = false,
  onToggle,
}) => {
  const hasCode = showPromoCode && code;

  const boxSubHeader = (): JSX.Element => {
    const text = isOnline ? 'No promo code needed' : 'No coupon needed';
    return (
      <div className="shared-ui__promo-expand-box__general" data-testid={DATA_TEST_GENERAL_PROMO_EXPAND_HEADER}>
        <PromoBox imageSrc={imageSrc} title={title} subTitle={subTitle} variant="dashed" />
        {toggleable && (
          <span className="shared-ui__promo-expand-box__general__content">
            {hasCode ? (
              <>
                Promo Code: <b>{code}</b>
              </>
            ) : (
              <>
                {endDate && <>Sale ends {endDate}. </>} {text}.
              </>
            )}
          </span>
        )}
      </div>
    );
  };

  return (
    <ExpandBox title={expandTitle} subHeaderElement={boxSubHeader()} toggleable={toggleable} onToggle={onToggle}>
      <div className="shared-ui__promo-expand-box__content" data-testid={DATA_TEST_GENERAL_PROMO_EXPAND_CONTENT}>
        <p className="shared-ui__promo-expand-box__content__description">
          {requiredPromoCode && code
            ? 'Make your purchase as you normally would, using the promo code above to secure this deal.'
            : 'Make your purchase as you normally would, using the best available sales and discounts.'}{' '}
          {!hasCode && !isOnline && 'No need to mention GoodBed.'}
        </p>
        {(hasCode || isOnline) && rewardDescription && (
          <p className="shared-ui__promo-expand-box__content__reward_description">
            <b>Offer Details:</b> Get {rewardDescription}
          </p>
        )}
      </div>
    </ExpandBox>
  );
};

export default GeneralPromoExpand;
