import React, { FC, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import cls from 'classnames';

export type OnToggleFnType = (open: boolean) => void;

export type ExpandBoxProps = {
  title: string;
  children?: ReactNode;
  subHeaderElement?: JSX.Element;
  toggleable?: boolean;
  onToggle: OnToggleFnType;
};

export const EXPAND_BOX_DATA_TEST_ID = 'expand-box';
export const DATA_TEST_TARGET = 'expand-box-target';
export const DATA_TEST_SUB_HEADER = 'expand-box-sub-header';
export const DATA_TEST_CONTENT = 'expand-box-content';

const ExpandBox: FC<ExpandBoxProps> = ({ title, children, subHeaderElement, toggleable = true, onToggle }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="share-ui__expand-box" data-testid={EXPAND_BOX_DATA_TEST_ID}>
      <div
        className="share-ui__expand-box__target"
        role="button"
        tabIndex={0}
        onClick={(): void => {
          if (toggleable) setIsOpen(!isOpen);
          if (toggleable && onToggle) {
            onToggle(!isOpen);
          }
        }}
        data-testid={DATA_TEST_TARGET}
      >
        <div className="share-ui__expand-box__target__main">
          <span>{title}</span> {toggleable && <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />}
        </div>
        {subHeaderElement && (
          <div className="share-ui__expand-box__target__sub-header" data-testid={DATA_TEST_SUB_HEADER}>
            {subHeaderElement}
          </div>
        )}
      </div>
      <div
        className={cls('share-ui__expand-box__content', {
          'share-ui__expand-box__content__open': isOpen,
        })}
        data-testid={DATA_TEST_CONTENT}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandBox;
