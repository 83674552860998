/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const SmallArrowDoodleIcon: FC<IconProps> = ({ className = 'small-arrow-doodle' }) => (
  <svg className={className} width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0973187 2.29961C1.06319 2.35587 1.62037 2.44921 2.11987 2.40388C6.30572 2.11997 10.0939 3.59899 13.5761 5.77733C14.6859 6.44411 15.651 7.55574 16.3395 8.64449C18.6234 12.3226 19.7099 16.4452 19.4668 21.0722C18.4519 20.4448 18.3124 19.3542 17.8342 18.5821C17.4054 17.8536 17.0221 17.1209 16.5108 16.4957C16.3541 16.2701 15.7969 16.1767 15.5326 16.2967C15.2683 16.4166 14.9914 16.9214 15.0159 17.207C15.1268 17.9644 15.283 18.7177 15.5714 19.4111C16.4984 21.6774 17.5121 23.8879 18.4804 26.1025C19.2139 27.7148 19.8701 27.8951 21.1506 26.8195C23.2447 25.0945 24.7036 22.8995 25.5807 20.3255C25.7255 19.8807 25.5897 19.3654 25.5779 18.6949C23.4391 19.3687 23.5115 21.8085 21.8142 22.8259C21.7859 21.965 21.77 21.247 21.7541 20.5289C21.7574 16.3074 20.774 12.3194 18.8533 8.60824C17.5385 6.08931 15.6412 4.24683 13.12 3.13251C10.4089 1.93949 7.62342 0.945094 4.68859 0.875644C3.21913 0.81712 1.70426 0.762717 0.0973187 2.29961Z"
      fill="#6281B8"
    />
  </svg>
);

export default SmallArrowDoodleIcon;
