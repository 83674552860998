import React from 'react';
import { createRoot } from 'react-dom/client';
import SUCompareWidget, { COMPARE_WIDGET_ID } from 'shared-ui/src/components/organism/CompareWidget';

function CompareWidget({
  productClassname,
  productSlug,
  productName,
  manufacturerSlug,
  manufacturerName,
  quizUrl,
  showSimilarSliderTop,
  isModelPage,
}) {
  return (
    <SUCompareWidget
      productClassname={productClassname}
      productSlug={productSlug}
      productName={productName}
      manufacturerSlug={manufacturerSlug}
      manufacturerName={manufacturerName}
      quizUrl={quizUrl}
      showSimilarSliderTop={showSimilarSliderTop}
      isModelPage={isModelPage}
    />
  );
}

export function renderCompareWidget() {
  const element = document.getElementById(COMPARE_WIDGET_ID);

  if (element) {
    const productClassname = element.getAttribute('data-classname');
    const productSlug = element.getAttribute('data-slug');
    const productName = element.getAttribute('data-name');
    const manufacturerSlug = element.getAttribute('data-manufacturer-slug');
    const manufacturerName = element.getAttribute('data-manufacturer-name');
    const quizUrl = element.getAttribute('data-quiz-url');
    const showSimilarSliderTop = element.getAttribute('data-show-similar-slider-top');
    const isModelPage = element.getAttribute('data-is-model-page');

    const root = createRoot(element);

    root.render(
      <CompareWidget
        productClassname={productClassname}
        productSlug={productSlug}
        productName={productName}
        manufacturerSlug={manufacturerSlug}
        manufacturerName={manufacturerName}
        quizUrl={quizUrl}
        showSimilarSliderTop={showSimilarSliderTop === 'True'}
        isModelPage={isModelPage === 'True'}
      />,
    );
  }
}

export default CompareWidget;
