/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import mapValues from 'lodash/mapValues';
import { BaseReviewsSummary, ReviewsSummary } from '../../../../types/review-summary';
import RatingCategory from '../../../molecules/RatingCategory';

type AverageLifespan = Pick<ReviewsSummary, 'average_lifespan'>;

interface CategoryRatingsProps {
  summaryData: Partial<BaseReviewsSummary & AverageLifespan> | undefined;
  type: 'review-list-summary' | 'individual-review-item';
  isMobile: boolean;
}

const MAX_RATING = 5;

const LIFE_SPAN = 'lifeSpan';
const SUPPORT = 'support';
const PRESSURE_RELIEF = 'pressureRelief';
const SLEEP = 'sleep';
const TEMPERATURE = 'temperature';
const VALUE = 'value';
const WARRANTY = 'warranty';

const CategoryRatings: FC<CategoryRatingsProps> = ({ summaryData, type = 'review-list-summary', isMobile }) => {
  const lifeSpanYears = summaryData?.average_lifespan?.value || 0;

  const values = {
    [LIFE_SPAN]: summaryData?.average_lifespan?.value || 0,
    [SUPPORT]: summaryData?.support?.value || 0,
    [PRESSURE_RELIEF]: summaryData?.pressure_relief?.value || 0,
    [SLEEP]: summaryData?.sleep?.value || 0,
    [TEMPERATURE]: summaryData?.temperature?.value || 0,
    [VALUE]: summaryData?.value?.value || 0,
    [WARRANTY]: summaryData?.warranty?.value || 0,
  };

  // Values changed from 0-5 to 0-100.
  const transformedValues = mapValues(values, (rating, key) => {
    if (key === LIFE_SPAN) return (rating / 12) * 100;

    return Math.trunc((rating / MAX_RATING) * 100);
  });

  const isReviewItem = type === 'individual-review-item';
  const isReviewListSummary = type === 'review-list-summary';

  const naLabel = <span className="na-label">N/A</span>;

  const getIndividualRatingValueLabel = (valueID: string): JSX.Element | undefined => {
    if (!values[valueID as keyof typeof values]) return naLabel;

    if (isReviewListSummary) return <>{transformedValues[valueID as keyof typeof transformedValues]}</>;

    if (isReviewItem)
      return (
        <div className="individual-review-item__value-label">
          <span className="individual-review-item__value-label__value">{`${
            values[valueID as keyof typeof values]
          }`}</span>
          <span className="individual-review-item__value-label__max">/ 5</span>
        </div>
      );

    return undefined;
  };

  const getLifeSpanLabel = (): JSX.Element => {
    if (!lifeSpanYears) return naLabel;

    return (
      <>
        {lifeSpanYears} {lifeSpanYears === 1 ? 'Year' : 'Years'}
      </>
    );
  };

  return (
    <div className="rating-categories-container">
      <RatingCategory
        id="back-support"
        rating={transformedValues[SUPPORT]}
        ratingValueLabel={getIndividualRatingValueLabel(SUPPORT)}
        label="Back Support"
        content={`How well this mattress holds your spine in a proper "neutral" position (roughly the same shape
  it has when you are standing) while you are sleeping. Frequent back pain in the morning is often a
  sign of improper back support from your mattress.`}
        isMobile={isMobile}
      />
      <RatingCategory
        id="pressure-relief"
        label="Pressure Relief"
        rating={transformedValues[PRESSURE_RELIEF]}
        ratingValueLabel={getIndividualRatingValueLabel(PRESSURE_RELIEF)}
        content={`How well this mattress minimizes pain or tingling in key "pressure points" — especially your
shoulders and/or hips when sleeping on your side. Mattresses can provide pressure relief by absorbing,
cushioning, and/or redistributing your body weight.`}
        isMobile={isMobile}
      />

      <RatingCategory
        id="sleep-quality"
        rating={transformedValues[SLEEP]}
        ratingValueLabel={getIndividualRatingValueLabel(SLEEP)}
        label="Sleep Quality"
        content="How well you sleep on this mattress relative to your previous one. Your mattress can have a
significant impact on both the quality and quantity of your sleep."
        isMobile={isMobile}
      />

      <RatingCategory
        id="temperature"
        label='Stays "Cool"'
        rating={transformedValues[TEMPERATURE]}
        ratingValueLabel={getIndividualRatingValueLabel(TEMPERATURE)}
        content="How well this mattress keeps you cool and comfortable while sleeping. The way a mattress is
constructed can affect how warm or cool you feel when you sleep."
        isMobile={isMobile}
      />
      <RatingCategory
        id="value"
        rating={transformedValues[VALUE]}
        ratingValueLabel={getIndividualRatingValueLabel(VALUE)}
        label="Value"
        content="The amount of use and enjoyment you receive from a product relative to the amount you paid for it."
        isMobile={isMobile}
      />
      <RatingCategory
        id="warranty"
        label="Warranty Service"
        rating={transformedValues[WARRANTY]}
        ratingValueLabel={getIndividualRatingValueLabel(WARRANTY)}
        content="How satisfactorily the manufacturer has responded to warranty claims or other problems with this
mattress, if any."
        isMobile={isMobile}
      />
      {isReviewListSummary ? (
        <RatingCategory
          id="average-lifespan"
          label="Average Lifespan"
          rating={transformedValues[LIFE_SPAN]}
          ratingValueLabel={getLifeSpanLabel()}
          content={`The average length of time that a mattress lasts before needing to be replaced. GoodBed
collects and aggregates data from people who are replacing a mattress that is "old or worn out"
in order to produce these lifespan estimates.`}
          isMobile={isMobile}
        />
      ) : null}
    </div>
  );
};

export default CategoryRatings;
