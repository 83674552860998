import { trackTakeQuizCTA } from '../analytics/cta';

const REBATE_MODAL = '.rebate-popup-modal';
const HOVER_POPOVER_CLASS = 'hover-popover';

function setPopoverPlacement(find, placement) {
  $(REBATE_MODAL)
    .find(find)
    .popover({
      html: true,
      placement,
    })
    .on('shown', () => {
      // eslint-disable-next-line
      popup
        .next('.popover')
        .find('.popover-close-btn')
        // eslint-disable-next-line
        .click(() => popup.popover('hide'));
    });
}

export function initializeRebatePopover(learnMorePlacement, iconPlacement) {
  function setPlacementLearnMore(placement) {
    setPopoverPlacement('.learn-more-popover', placement);
  }

  function setPlacementIcon(placement) {
    setPopoverPlacement('.icon-popover', placement);
  }

  setPlacementLearnMore(learnMorePlacement);
  setPlacementIcon(iconPlacement);

  // delegating click to <a> doesn't work.
  $(REBATE_MODAL)
    .find('a[data-js=show-code-ref]')
    .on('click', () => {
      $(REBATE_MODAL).find('a[data-source] .btn--coupon-code').click();
    });

  // Remove 'modal-open' class from <body> so it remains scrollable

  function removeModalOpenClass() {
    $('body').removeClass('modal-open');
    $(document).off('focusin.modal');
  }

  $(REBATE_MODAL).on('shown.bs.modal', removeModalOpenClass);
  // Make sure?
  if ($(REBATE_MODAL).length) {
    removeModalOpenClass();
  }
}

export function setupClosePopoverOnClickOutside() {
  // Close popovers when clicking outside them.
  $('body').on('click', (e) => {
    $('[data-original-title]').each((idx, element) => {
      // hide any open popovers when the anywhere else in the body is clicked
      if (
        !$(element).is(e.target) &&
        $(element).has(e.target).length === 0 &&
        $('.popover').has(e.target).length === 0
      ) {
        $(element).popover('hide');
      }
    });
  });
}

export function initializePopovers() {
  $('[rel=tooltip]').tooltip({ container: '#container' });

  // Initialize tooltips
  $('[data-toggle="tooltip"]').each((idx, element) => {
    const $this = $(element);

    $this.tooltip({ container: $this[0] || '#container' });
  });

  $('.alert').alert();
  $('[rel=clickover]').popover({ container: '#container' });

  $('[rel=popover]').each((idx, element) => {
    const $this = $(element);

    if ($this.data('clickable') && $this.data('clickable') !== 'true') {
      // eslint-disable-next-line
      e.preventDefault();
    }

    $this
      .popover({
        html: true,
        delay: { hide: 150 },
        placement(popoverElement) {
          // We add this class to hover popovers so we can identify them.
          $(popoverElement).addClass(HOVER_POPOVER_CLASS);
          return $this.data('placement') ? $this.data('placement') : 'bottom';
        },
        trigger: 'hover',
        container: '#container',
      })
      .on('hide.bs.popover', () => {
        // Prevent the hide event on hover popovers so we can trigger it manually.
        if ($(`.${HOVER_POPOVER_CLASS}:hover`).length) return false;

        return true;
      });
  });

  // Only trigger the hide event on hover popovers when the cursor exits
  // the trigger element or the popover itself.
  $('body').on('mouseleave', `.${HOVER_POPOVER_CLASS}`, () => {
    $(`.${HOVER_POPOVER_CLASS}`).popover('hide');
  });

  $("[rel='popover-clickable']").each((idx, element) => {
    const $this = $(element);

    $this.popover({
      html: true,
      delay: { hide: 150 },
      placement() {
        const windowWidth = $(window).width();

        if ($this.data('place-bottom-on-mobile') && windowWidth <= 576) {
          return 'bottom';
        }

        return $this.data('placement') || 'bottom';
      },
      container: '#container',
    });

    $this.on('shown.bs.popover', trackTakeQuizCTA);
  });

  $('.help-popover').popover({ trigger: 'click' });

  $('a.inline-popup').colorbox({
    inline: true,
    width: '530px',
    fixed: true,
    onClosed: () => {
      if (window.location.hash.match('^#change-location')) {
        const pos = $(window).scrollTop();
        window.location.hash = '';
        $(window).scrollTop(pos);
      }
    },
  });

  $('#q').typeahead({
    source: (query, process) => {
      return $.get('/autocomplete/universal_search/', { q: query }, (data) => process(data.map((item) => item.result)));
    },
    sorter: (items) => items,
    updater(item) {
      const $el = this.$element;
      $el.val(item.result);
      $el.closest('form').submit();
      return item;
    },
    items: 35,
    minLength: 1,
  });
}

export function hidePopover(e) {
  if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    $(this).popover('hide');
  }
}
