import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import useFetchProductDeal from 'shared-ui/src/hooks/useFetchProductDeal';
import SUBestDeal, { BEST_DEAL_ID } from 'shared-ui/src/components/molecules/BestDeal';
import { LINE, MANUFACTURER, MATTRESS, MATTRESS_COLLECTION } from 'shared-ui/src/constants/commons';

function BestDeal({ productClassname, productSlug, pageRegion, nearestDeals = false, onGetDeal }) {
  const data = useFetchProductDeal(productClassname, productSlug, nearestDeals);

  return data.hasRequestFinished ? (
    <SUBestDeal
      pageRegion={pageRegion}
      deal={{ brand: data.brand, deals: data.deals }}
      nearestDeals={nearestDeals}
      onGetDeal={onGetDeal}
    />
  ) : null;
}

BestDeal.propTypes = {
  productClassname: PropTypes.oneOf([MANUFACTURER, LINE, MATTRESS_COLLECTION, MATTRESS]).isRequired,
  productSlug: PropTypes.string.isRequired,
  pageRegion: PropTypes.string.isRequired,
  nearestDeals: PropTypes.bool,
};

export default BestDeal;

export function renderBestDeal(onGetDeal, selector = `[id=${BEST_DEAL_ID}]`) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const classname = element.getAttribute('data-classname');
    const slug = element.getAttribute('data-slug');
    const pageRegion = element.getAttribute('data-page-region');
    const nearestDeals = element.getAttribute('data-nearest-deals');

    const root = createRoot(element);

    root.render(
      <BestDeal
        productClassname={classname}
        productSlug={slug}
        pageRegion={pageRegion}
        nearestDeals={nearestDeals === 'true'}
        onGetDeal={onGetDeal}
      />,
    );
  });
}
