import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const PlusIcon: FC<IconProps> = ({ color = 'white', width = 14 }) => (
  <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 1V13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 7H1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PlusIcon;
