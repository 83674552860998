import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import SaleModal from '../modals/sale-modal';
import {
  LDP_METRICS_STORE_LOCATOR_PAGE,
  LDP_METRICS_TRY_THIS_BED_PAGE,
  LDP_METRICS_OTHER_PAGE,
  LDP_METRICS_PROFILE_PAGE,
  LDP_METRICS_STORE_CLASSNAME,
  LDP_METRICS_RETAILER_CLASSNAME,
} from '../../../constants';

class SalePromoButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleModal = this.handleModal.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  handleModal() {
    this.setState({ show: true });
  }

  onHide() {
    this.setState({ show: false });
  }

  render() {
    const { show } = this.state;
    const {
      storeSlug,
      storeName,
      retailerName,
      promotions,
      externalURL,
      outboundURL,
      externalDomain,
      locationPage,
      objectClassname,
      manufacturerId,
      isLDP,
    } = this.props;

    return (
      <>
        <button
          type="button"
          className="compact-promo-box sale-promotion-btn"
          // eslint-disable-next-line
          rel="nofollow"
          onClick={this.handleModal}
        >
          <b>SALE</b>
        </button>
        {show && (
          <SaleModal
            storeSlug={storeSlug}
            storeName={storeName}
            retailerName={retailerName}
            promotions={promotions}
            externalURL={externalURL}
            outboundURL={outboundURL}
            externalDomain={externalDomain}
            locationPage={locationPage}
            objectClassname={objectClassname}
            manufacturerId={manufacturerId}
            isLDP={isLDP}
            onShow={this.handleModal}
            onHide={this.onHide}
          />
        )}
      </>
    );
  }
}

SalePromoButton.defaultProps = {
  promotions: [],
  externalURL: '',
  outboundURL: '',
  externalDomain: '',
  locationPage: undefined,
  objectClassname: undefined,
  isLDP: false,
};

SalePromoButton.propTypes = {
  storeSlug: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  retailerName: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      classname: PropTypes.oneOf(['brandpromotion', 'retailerpromotion', 'promotion']).isRequired,
      description: PropTypes.string,
      product_name: PropTypes.string,
      retailer_name: PropTypes.string,
      store_name: PropTypes.string,
      title: PropTypes.string.isRequired,
      title_short: PropTypes.string.isRequired,
    }),
  ),
  externalURL: PropTypes.string,
  outboundURL: PropTypes.string,
  externalDomain: PropTypes.string,
  locationPage: PropTypes.oneOf([
    LDP_METRICS_STORE_LOCATOR_PAGE,
    LDP_METRICS_TRY_THIS_BED_PAGE,
    LDP_METRICS_OTHER_PAGE,
    LDP_METRICS_PROFILE_PAGE,
  ]),
  objectClassname: PropTypes.oneOf([LDP_METRICS_STORE_CLASSNAME, LDP_METRICS_RETAILER_CLASSNAME]),
  manufacturerId: PropTypes.number,
  isLDP: PropTypes.bool,
};

export default function initPromoButton() {
  document.querySelectorAll('[id=sale-btn]').forEach((element) => {
    const storeSlug = element.getAttribute('data-store-slug');
    const storeName = element.getAttribute('data-store-name');
    const retailerName = element.getAttribute('data-retailer-name');
    let promotions = element.getAttribute('data-promotions');
    const externalURL = element.getAttribute('data-external-url');
    const outboundURL = element.getAttribute('data-outbound-url');
    const externalDomain = element.getAttribute('data-external-domain');
    const locationPage = element.getAttribute('data-location-page');
    const objectClassname = element.getAttribute('data-object-classname');
    const manufacturerId = element.getAttribute('data-manufacturer-id');
    const isLDP = element.getAttribute('data-ldp');

    if (promotions) promotions = JSON.parse(promotions);

    const root = createRoot(element);

    root.render(
      <SalePromoButton
        storeSlug={storeSlug}
        storeName={storeName}
        retailerName={retailerName}
        promotions={promotions}
        externalURL={externalURL}
        outboundURL={outboundURL}
        externalDomain={externalDomain}
        locationPage={locationPage}
        objectClassname={objectClassname}
        manufacturerId={manufacturerId}
        isLDP={isLDP ? isLDP === 'True' : false}
      />,
    );
  });
}
