import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import SUBrandPromoBox, { BRAND_PROMO_BOX_ID } from 'shared-ui/src/components/molecules/BrandPromoBox';

function BrandPromoBox({ deal, pageRegion, onGetDeal }) {
  return <SUBrandPromoBox deal={deal} pageRegion={pageRegion} onGetDeal={onGetDeal} />;
}

BrandPromoBox.propTypes = {
  deal: PropTypes.shape,
  pageRegion: PropTypes.string.isRequired,
  onGetDeal: PropTypes.func.isRequired,
};

export default BrandPromoBox;

export function renderBrandPromoBox(onGetDeal, selector = `[id=${BRAND_PROMO_BOX_ID}]`) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const pageRegion = element.getAttribute('data-page-region');

    const root = createRoot(element);
    root.render(<BrandPromoBox pageRegion={pageRegion} onGetDeal={onGetDeal} />);
  });
}
